export const grantStatusData = [{
  value: 'toBeVerified',   //状态值
  label: '待验证',   //状态名
  explain: '需要手机验证码验证',   //说明
  scene: '实体奖品/服务体检券/现金转账',  //应用场景
  inviteBtnClass: '',  //邀请按钮class
  prizePopField: 'showPrizeBg',  //弹窗-背景大小
}, {
  value: 'toBePicVerified',
  label: '待验证',
  explain: '需要图片验证码验证',
  scene: '实体奖品/服务体检券/现金转账',
  inviteBtnClass: '',
  prizePopField: 'showPrizeBg',
}, {
  value: 'unclaimed',
  label: '待领取',
  explain: '1.验证码通过后，或者没有验证码，出现此状态。\n 2.如果是实体奖品，需要用户填写物流信息  ',
  scene: '实体奖品/服务体检券',
  inviteBtnClass: '',
  prizePopClass: 'prize-mini-raffle',
  prizePopField: 'showPrize2',
}, {
  value: 'delivered',
  label: '已发货',
  explain: '在管理后台，填写完物流信息，出现此状态',
  scene: '实体奖品，邮寄时',
  inviteBtnClass: '',
  prizePopField: '',
}, {
  value: 'received',
  label: '已领取',
  explain: 'receive',
  scene: '实体奖品/服务体检券/现金转账',
  inviteBtnClass: '',
  prizePopField: '',
}, {
  value: 'expire',
  label: '过期',
  explain: '奖品到期还没有验证或者还没有领取',
  scene: '实体奖品/服务体检券/现金转账',
  inviteBtnClass: '',
  prizePopField: '',
}, {
  value: 'toBeDelivered',
  label: '待发货',
  explain: '用户填写完物流信息，出现此状态',
  scene: '实体奖品，邮寄时',
  inviteBtnClass: '',
  prizePopField: '',
}, {
  value: 'wxRedEnvelopeFail',
  label: '转账失败',
  explain: '',
  scene: '现金转账',
  inviteBtnClass: '',
  prizePopField: '',
}, {
  value: 'wxRedEnvelopeToBeIssued',
  label: '待发放',
  explain: '验证码通过后，或者没有验证码，出现此状态',
  scene: '现金转账',
  inviteBtnClass: 'receive',
  prizePopField: 'showPrize2',
}, {
  value: 'wxRedEnvelopeIssued',
  label: '已发放',
  explain: '成功调用微信接口，并获取微信对应的订单号，出现此状态，奖金已发，但是不代表用户领取了（微信红包需要用户点击）',
  scene: '现金转账',
  inviteBtnClass: 'receive',
  prizePopField: '',
}]