<template>
  <div>
    微信登录页
  </div>
</template>
<script>
export default {
  name: "bsgl-login",
  data() {
    return {

    }
  },
  mounted(){

  },
  computed:{

  },
  methods:{

  },
}
</script>
<style scoped lang='scss'>

</style>
