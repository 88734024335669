import qs from 'qs'
import router from '@/router'
import store from '@/store'
import wechatAuth from '@/plugins/wechatAuth'
import { baseconfig } from "@/config.js";
import { loginByCode } from '@/request/api'
// 设置APPID
wechatAuth.setAppId(baseconfig.appid)
const whiteList = ['/404']
router.beforeEach(async (to, from, next) => {
  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) return next();
  const { loginStatus } = store.getters
  console.log(loginStatus, '登陆状态', window.location.href)
  switch (Number(loginStatus)) {
    case 0:
      // 获取跳转地址
      wechatAuth.redirect_uri = processUrl()
      await store.dispatch('user/setLoginStatus', 1)
      window.location.href = wechatAuth.authUrl
      break
    case 1:
      // alert('正在登录中...' + window.location.href)
      try {
        wechatAuth.returnFromWechat(window.location.href)
        const code = wechatAuth.code
        console.log('1-code', code)
        // 通过code换取token
        loginByCode({ code: code }).then(res => {
          store.dispatch('user/loginWechatAuth', res.data)
          store.dispatch('user/setLoginStatus', 2)
          if (process.env.NODE_ENV !== 'development' && router.mode === 'hash') {
            console.log(window.location.origin, window.location.pathname, window.location.hash)
            let timer = setInterval(() => {
              let wxOpenId = store.state.user.userInfo.wxOpenId
              // alert('wxOpenId: ' + wxOpenId)
              if (wxOpenId) {
                clearInterval(timer)
                // alert('wxOpenId-重新跳转url: ' + window.location.origin + window.location.pathname + window.location.hash)
                // window.location.href = window.location.origin + window.location.pathname + window.location.hash  // 感觉页面没有跳转刷新 window.location.reload()
                next();  //换为next()
              }
            }, 50)
          } else {
            let timer = setInterval(() => {
              let wxOpenId = store.state.user.userInfo.wxOpenId
              if (wxOpenId) {
                clearInterval(timer)
                next()
              }
            }, 50)
          }
        })
        // hash
      } catch (err) {
        await store.dispatch('user/setLoginStatus', 0)
        next('/404')
      }
      break
    case 2:
      next()
      break
    default:
      break
  }
})


/**
 * 处理url链接
 * @returns {string}
 */
function processUrl () {
  // // 本地环境换通过auth.html拿code
  // if (process.env.NODE_ENV === 'development') return `${baseconfig.VUE_APP_WECHAT_AUTH_URL}?backUrl=${encodeURIComponent(window.location.href)}`;   //中间授权页地址
  const url = `${baseconfig.VUE_APP_WECHAT_AUTH_URL}?backUrl=${encodeURIComponent(window.location.href)}`  //window.location.href
  console.log('processUrl', url)
  // 解决多次登录url添加重复的code与state问题
  const urlParams = qs.parse(url.split('?')[1])
  let redirectUrl = url
  if (urlParams.code && urlParams.state) {
    delete urlParams.code
    delete urlParams.state
    const query = qs.stringify(urlParams)
    if (query.length) {
      redirectUrl = `${url.split('?')[0]}?${query}`
    } else {
      redirectUrl = `${url.split('?')[0]}`
    }
  }
  return redirectUrl
}
