
/**
 *******工具类***********
 *
 */
const utils = {

	/**
	 * 保留两位小数
	 * @param {*} value  值
	 * @param {*} point  几位小数
	 * @returns  结果
	 */
	fmtToFix (value = 0, point = 2, isMend = true) {
		value = Number(value)
		if (isMend) return value.toFixed(point); //此处2为保留两位小数
		return parseFloat(value.toFixed(point)); //此处2为保留两位小数
	},

	/**
	 * @function 保留整数
	 * @param value 
	 */
	fmtParseInt (value = 0) {
		return parseInt(value);
	},

	/**
	 * @function 处理价格-整数、小数分开
	 * @param value  值
	 * @param isRepair  是否补0
	 * @param isSpot  是否有小数点
	 * @returns 
	 */
	formatPriceTran (value = 0, isRepair = true, isSpot = true) {
		value = Number(value).toFixed(1)
		var price = {}
		if (isSpot) {
			var pointsPointsNum = value.indexOf('.')
			price.price_int = value.substring(0, pointsPointsNum) // 商品价钱的整数
			price.price_decimal = isRepair ? value.substring(pointsPointsNum) : (Number(value.split('.')[1]) > 0 ? value.substring(pointsPointsNum) : '') // 商品价钱的小数
		} else {
			price.price_int = parseInt(value)
			price.price_decimal = isRepair ? value.split('.')[1] : (Number(value.split('.')[1]) > 0 ? value.split('.')[1] : '')
		}
		return price
	},

	/**
	 * @function 数字转换成-千、万、亿
	 * @param {*} value   //值
	 * @param {*} point  小数点后几位
	 * @param {*} unit  单位 0:字母小写（默认）、1:字母大写、2:汉字
	 */
	formatTranNum (value = 0, point = 2, unit = 0, isSplit = false) {
		var numStr = value.toString().split('.')[0] // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
		if (numStr.length < 4) { // 判断数字有多长,如果小于4,,表示千以内的数字,让其直接显示
			if (isSplit) {
				return { num_str: this.fmtToFix(value), num_unit: '' }
			} else {
				return this.fmtToFix(value,0);
			}
		} else if (numStr.length >= 4 && numStr.length < 5) {
			let decimal = numStr.substring(numStr.length - 3, numStr.length - 3 + point)
			if (isSplit) {
				return { num_str: parseFloat(parseInt(value / 1000) + '.' + decimal), num_unit: `${unit == 1 ? 'K' : unit == 2 ? '千' : 'k'}` }
			} else {
				return parseFloat(parseInt(value / 1000) + '.' + decimal) + `${unit == 1 ? 'K' : unit == 2 ? '千' : 'k'}`
			}
		} else if (numStr.length >= 5 && numStr.length <= 8) { // 如果数字大于6位,小于8位,让其数字后面加单位万
			let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
			if (isSplit) {
				return { num_str: parseFloat(parseInt(value / 10000) + '.' + decimal), num_unit: `${unit == 1 ? 'W' : unit == 2 ? '万' : 'w'}` }
			} else {
				return parseFloat(parseInt(value / 10000) + '.' + decimal) + `${unit == 1 ? 'W' : unit == 2 ? '万' : 'w'}`
			}
		} else if (numStr.length > 8) { // 如果数字大于8位,让其数字后面加单位亿
			let decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
			if (isSplit) {
				return { num_str: parseFloat(parseInt(value / 100000000) + '.' + decimal), num_unit: `${unit == 1 ? '亿' : unit == 2 ? '亿' : '亿'}` }
			} else {
				return parseFloat(parseInt(value / 100000000) + '.' + decimal) + `${unit == 1 ? '亿' : unit == 2 ? '亿' : '亿'}`
			}
		}
	},


};

module.exports = utils;