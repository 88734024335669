import axios from 'axios'
import store from '@/store'
import { baseconfig } from "@/config.js";
import { Toast } from 'mint-ui';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || '/api', // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['X-WX-TOKEN'] = store.getters.token
    }
    if (store.getters.userInfo && store.getters.userInfo.wxOpenId) {
      config.headers['X-WX-OPEN-ID'] = store.getters.userInfo.wxOpenId
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    // Indicator.close();
    const res = response.data
    if (res) {
      if (res.code == 266666) {
        Toast({
          message: '用户信息校验失败，重新授权登录',
          position: "middle",
          duration: 1500,
        });
        store.dispatch('user/fedLogOut').then(() => {
          location.reload()
        })
        return Promise.reject(res || 'error')
      } else {
        return Promise.resolve(res)
      }
    } else {
      return Promise.reject(res || 'error')
    }

  },
  error => {
    // Indicator.close();
    Toast({
      message: baseconfig.net_error_message,
      position: "middle",
      duration: 1500,
    });
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
