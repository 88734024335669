import qs from 'qs'
import request from '@/utils/request'

/**
 * 登录接口请求token与userinfo
 * @param params {code: code}
 */
export function loginByCode (params) {
  return request({
    url: '/operation/app/activity/getUserInfoByCode',
    method: 'get',
    params
  })
}
/**
 * 获取登录用户信息
 * @param params
 */
export function getUserInfo (params) {
  return request({
    url: '/user/get_user',
    method: 'post',
    data: qs.stringify(params)
  })
}
export function wxSign (params) {
  return request({
    url: '/operation/app/activity/getUrlWxSignInfo',
    method: 'post',
    data: params
  })
}
/**
 * 获取用户活动信息
 * @param params
 */
export function getUserActivityInfo (params) {
  return request({
    url: '/operation/app/activity/getUserActivityInfo',
    method: 'post',
    data: params
  })
}
/**
 * 通过活动编码获取活动信息
 * @param params
 */
export function getActivityByActivityCode (params) {
  return request({
    url: '/operation/app/activity/getActivityByActivityCode',
    method: 'get',
    params
  })
}
/**
 * 通过活动编码获取活动参与人数中奖记录
 * @param params
 */
export function getActivityRaffleInfo (params) {
  return request({
    url: '/operation/app/activity/getActivityRaffleInfo',
    method: 'get',
    params
  })
}
/**
 * 获取用户抽奖记录列表
 * @param params
 */
export function getUserRaffleRecordList (params) {
  return request({
    url: '/operation/app/activity/getUserRaffleRecordList',
    method: 'get',
    params
  })
}
/**
 * 获取用户抽奖记录明细（单条抽奖记录）
 * @param params
 */
export function getUserRaffleDetail (params) {
  return request({
    url: '/operation/app/activity/getUserRaffleDetail',
    method: 'get',
    params
  })
}
/**
 * 发送短信验证码
 * @param params
 */
export function sendSmsVerifyCode (params) {
  return request({
    url: '/operation/app/activity/sendSmsVerifyCode',
    method: 'post',
    data: params
  })
}
/**
 * 修改用户抽奖记录（兑奖或者输入收货信息）
 * @param params
 */
export function editUserRaffleDetail (params) {
  return request({
    url: '/operation/app/activity/editUserRaffleDetail',
    method: 'post',
    data: params
  })
}
/**
 * 抽奖
 * @param params
 */
export function joinRaffle (params) {
  return request({
    url: '/operation/app/activity/joinRaffle',
    method: 'post',
    data: params
  })
}
/**
 * 获取企业微信
 * @param params
 */
export function getEnWxLink (params) {
  return request({
    url: '/operationManagement/activity/config/getEnWxLink',
    method: 'post',
    data: params
  })
}

/**
 * 邀请奖励 - 列表
 * @param {*} params 
 */
export function getInviteRewardInfo (params) {
  return request({
    url: '/operation/app/activity/getUserInviteRewardList',
    method: 'get',
    params
  })
}

/**
 * 邀请奖励 - 邀请记录列表
 * @param {*} params 
 */
export function getInviteRecordInfo (params) {
  return request({
    url: 'operation/app/activity/getUserInviteRecordList',
    method: 'get',
    params
  })
}

/**
 * 邀请奖励 - 领奖
 * @param {*} params 
 */
export function postReceiveInviteReward (params) {
  return request({
    url: '/operation/app/activity/editUserInviteRewardDetail',
    method: 'post',
    data: params
  })
}

/**
 * 获取图形验证码
 * @param {*} params 
 */
export function postPicVerifyInfo (params) {
  return request({
    url: '/operation/app/activity/getPicVerifyCode',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}

/**
 * 邀请奖励 - 领奖
 * @param {*} params 
 */
export function postUserInviteRewardInfo (params) {
  return request({
    url: '/operation/app/activity/editUserInviteRewardDetail',
    method: 'post',
    data: params
  })
}


/**
 * 邀请奖励 - 获取用户分佣奖励信息
 * @param {*} params 
 */
export function getInviteRewordRecordInfo (params) {
  return request({
    url: '/operation/app/activity/inviteCommission/getUserReword',
    method: 'get',
    params
  })
}
/**
 * 邀请奖励 - 获取活动邀请分佣信息（分佣规则，排行榜）
 * @param {*} params 
 */
export function getInviteCommissionRankInfo (params) {
  return request({
    url: '/operation/app/activity/inviteCommission/getInfo',
    method: 'get',
    params
  })
}



/**
 * 分享海报
 */
export function getActivitySharePosterInfo (params) {
  return request({
    url: '/operation/app/activity/getActivityPoster',
    method: 'get',
    params
  })
}