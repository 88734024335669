import { render, staticRenderFns } from "./lotteryRecords.vue?vue&type=template&id=980ea120&scoped=true"
import script from "./lotteryRecords.vue?vue&type=script&lang=js"
export * from "./lotteryRecords.vue?vue&type=script&lang=js"
import style0 from "./lotteryRecords.vue?vue&type=style&index=0&id=980ea120&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "980ea120",
  null
  
)

export default component.exports