import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '@/permission'
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/style/common.css";
import "./assets/style/main.css";
import 'lib-flexible'
import { Toast, Indicator, Popup, Radio, Field, Swipe, SwipeItem } from "mint-ui";
import VConsole from 'vconsole'
import utils from "./utils/util.js"

Vue.config.ignoredElements.push('wx-open-launch-weapp')

if (process.env.VUE_APP_TITLE === 'test') {
  const vConsole = new VConsole()
  Vue.use(vConsole)
} else {  // if (process.env.VUE_APP_TITLE === 'production')
  let userInfoStr = localStorage.getItem('act_User-Info')
  if (userInfoStr) {
    let userInfo = JSON.parse(userInfoStr)
    if (userInfo.wxOpenId) {
      let whiteArr = ['opZSC6d8DKb4UoD51GyoZJ0StkWI', 'opZSC6VitlaHK44XHcSoXsxxDiP8', 'opZSC6WHZMwAdyYQNOH0cRceRJ6E', 'opZSC6QnUE_jNUWOF8xaZHbw58mY', 'opZSC6SKGqy9as7RmdasUOGBDuHA','opZSC6feGGk8W-gYv1nyCPELpM4M']
      if (whiteArr.includes(userInfo.wxOpenId)) {
        const vConsole = new VConsole()
        Vue.use(vConsole)
      }
    }
  }
}

Vue.prototype.$toast = Toast
Vue.prototype.$indicator = Indicator
Vue.prototype.$util = utils

Vue.config.productionTip = false
Vue.component(Popup.name, Popup);
Vue.component(Radio.name, Radio);
Vue.component(Field.name, Field);
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
