<template>
  <div class="container">
    <div class="lottery-main" data-html2canvas-ignore>
      <div class="my-lottery" @click="goLotteryRecords"></div>
      <div class="lottery-qt" :class="{ 'lottery-qt-active': isLottery }">
        <div class="lottery-btn" @click="handleLottery"></div>
        <div class="lucky-num"><em>{{$util.formatTranNum(currentRaffleTimes||0,0)}}</em>次机会</div>
      </div>
    </div>
    <div class="page-lottery-rule-content-part-wrap-box">
      <!-- 增加抽奖次数规则 activityInfo.enableExtRaffle && activityInfo.extRaffleList.length > 0-->
      <div v-if="activityInfo.enableExtRaffle && extRaffleList.length" class="lottery-rule-container lottery-enable-ext-raffle-box" data-html2canvas-ignore>
        <img class="lottery-rule-pic" src="./components/assets/img17.png" alt="" />
        <div class="lottery-rule lottery-rule-margin">
          <div class="lottery-rule-item" v-for="(el, index) in extRaffleList" :key="index">
            <div class="share-left">
              <img class="lottery-rule-share-icon" src="./components/assets/img24.png" alt="" />
              <span v-if="el.extRaffle === 'invite'">召唤好友参加,增加抽签次数</span>
              <span v-if="el.extRaffle === 'aiDetect'">完成小程序测肤，增加3次抽签次数</span>
            </div>
            <div class="share-btn" @click="showShare" v-if="el.extRaffle === 'invite'">召唤</div>
            <div class="share-btn detect-btn" v-if="el.extRaffle === 'aiDetect'">
              <span>去测肤</span>
              <wx-open-launch-weapp class="wx-open-launch-weapp-box" id="launch-btn" appid="wxb0214016e60403cd" username="gh_b04991ae11a8" path="packages/bsgl/SkinMeasurement/index" @launch="handleLaunchFn" @error="handleErrorFn">
                <script type="text/wxtag-template">
                  <div style="position:absolute; top: 0px;left: 0px;width:100%;height:100%;opacity: 0;"></div> 
              </script>
              </wx-open-launch-weapp>
            </div>
          </div>
        </div>
      </div>
      <!-- 新的-邀请奖励、活动奖品 -->
      <div class="lottery-rule-container lottery-rule-container-margin" data-html2canvas-ignore>
        <img class="lottery-rule-pic" src="./components/assets/img17.png" alt="" v-if="!activityInfo.enableExtRaffle && !extRaffleList.length" />
        <div class="lottery-rule lottery-rule-invite-cash-prize-task-part-box">
          <div class="invite-cash-prize-task-tab-header-part-box">
            <div class="cash-prize-task-tab-content-box">
              <div class="cash-prize-task-tab-item" :class="{'is-selected':tabActive==1}" @click="clickChangeTabActiveBtn(1)">活动奖</div>
              <div class="cash-prize-task-tab-item" :class="{'is-selected':tabActive==0}" @click="clickChangeTabActiveBtn(0)">任务奖</div>
              <div class="cash-prize-task-tab-item" :class="{'is-selected':tabActive==2}" @click="clickChangeTabActiveBtn(2)" v-if="activityInfo.enableInviteCommission">特别奖</div>
            </div>
          </div>
          <div class="invite-cash-prize-task-tab-body-part-wrap-box">
            <div class="invite-cash-task-tab-content-part-box" v-if="tabActive==0">
              <div class="invite-cash-task-two-version-body-part-box" v-if="!activityInfo.enableInviteCommission">
                <div class="lottery-rule-title">
                  <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
                  <div>邀伙伴领现金</div>
                  <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
                </div>
                <div class="lottery-rule-invite-cash-task-box">
                  <div class="lottery-rule-invite-cash-task-data-box">
                    <mt-swipe :auto="0" :show-indicators="false" :loop="false" :defaultIndex="inviteTaskSwiperIndex" @change="changeSwiperInviteTask">
                      <mt-swipe-item v-for="(item,index) in inviteTaskList" :key="index">
                        <div class="invite-cash-task-list-item-box">
                          <div class="task-list-item-left-box">
                            <p class="text-task-title">邀请<b>{{ item.inviteCount }}</b>人奖励</p>
                            <p class="text-task-tips">
                              <span v-if="item.lackCount>0">加油哦！还差{{item.lackCount}}人</span>
                              <span v-else> </span>
                            </p>
                            <div class="task-list-item-btn-box">
                              <div class="task-item-btn lack" v-if="!item.grantStatus">领取现金</div>
                              <div class="task-item-btn" v-if="item.grantStatus" :class="[fmtStatusShow(item.grantStatus,'inviteBtnClass')]" @click="clickReceiveInviteTaskItemBtn(item)">{{fmtStatusShow(item.grantStatus,'label')}}</div>
                            </div>
                          </div>
                          <div class="task-list-item-right-box">{{item.rewardAmount}}</div>
                        </div>
                      </mt-swipe-item>
                    </mt-swipe>
                  </div>
                  <div class="lottery-rule-invite-cash-task-slide-box">
                    <div class="invite-cash-task-slide-bar">
                      <div class="task-slide-line" v-for="(item,index) in inviteTaskList" :key="index" :style="{width:`${100/inviteTaskList.length/75}rem`}"></div>
                    </div>
                  </div>
                </div>
                <div class="lottery-rule-invite-cash-people-num-box">
                  <span>已邀请</span>
                  <div class="people-num-box">
                    <div class="people-num">{{userInviteCount}}</div>
                  </div>
                  <span>位小伙伴</span>
                </div>
                <div class="lottery-rule-content-bor lottery-rule-record-part-box">
                  <vue-seamless-scroll :data="inviteRecordList" :class-option="inviteScrollOption" class="lottery-record" v-if="userInviteCount&&inviteRecordList.length">
                    <div class="lottery-record-item" v-for="(item, index) in inviteRecordList" :key="index">
                      <div class="record-item-left-part-box">
                        <div class="record-item-index">{{ index+1 }}</div>
                        <div class="record-item-header-name-part-box">
                          <img class="head-img" alt="" :src="item.avatarUrl" />
                          <div class="record-item-name">{{item.nickName}}</div>
                        </div>
                      </div>
                      <!-- <div class="record-item-right-part-box">
                      <div class="record-item-time-part-box">2024-07-11 18:22:22</div>
                    </div> -->
                    </div>
                  </vue-seamless-scroll>
                  <div class="lottery-rule-record-no-data-box" v-if="!userInviteCount&&!inviteRecordList.length">暂无邀请数据~</div>
                </div>
              </div>
              <div class="invite-cash-task-three-version-body-part-box" v-if="activityInfo.enableInviteCommission">
                <div class="invite-cash-task-three-version-body-data-box">
                  <div class="lottery-rule-title">
                    <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
                    <div>邀伙伴领现金</div>
                    <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
                  </div>
                  <div class="task-three-version-invite-info-part-box">
                    <div class="task-three-version-invite-item-box">
                      <div class="task-three-invite-num-bg-part-box">{{inviteTaskCommissionRewordObj.invitedTaskCount}}</div>
                      <span class="text-task-three-tips">已邀请人数</span>
                    </div>
                    <div class="task-three-version-invite-item-box">
                      <div class="task-three-invite-num-bg-part-box">
                        <p>{{$util.formatPriceTran(inviteTaskCommissionRewordObj.invitedTaskAmount,false).price_int}}<span>{{$util.formatPriceTran(inviteTaskCommissionRewordObj.invitedTaskAmount,false).price_decimal}}</span></p>
                      </div>
                      <span class="text-task-three-tips">已获得金额</span>
                    </div>
                  </div>
                  <div class="lottery-rule-content-bor lottery-rule-record-part-box">
                    <div class="lottery-record-task-three-header-part-box">
                      <div class="record-item-index"></div>
                      <div class="record-item-header-name-part-box">昵称</div>
                      <div class="record-item-time-part-box">邀请时间</div>
                      <div class="record-item-bonus-part-box">奖金</div>
                    </div>
                    <vue-seamless-scroll :data="inviteTaskCommissionRewordObj.inviteTaskRewordList" :class-option="inviteScrollRewordOption" class="lottery-record">
                      <div class="lottery-record-item" v-for="(item, index) in inviteTaskCommissionRewordObj.inviteTaskRewordList" :key="index">
                        <div class="record-item-left-part-box">
                          <div class="record-item-index">{{ index+1 }}</div>
                          <div class="record-item-header-name-part-box">
                            <img class="head-img" alt="" :src="item.avatarUrl" />
                            <span class="record-item-name">{{item.nickName}}</span>
                          </div>
                        </div>
                        <div class="record-item-right-part-box">
                          <div class="record-item-time-part-box">{{item.inviteTime}}</div>
                          <div class="record-item-bonus-part-box">{{item.amount}}<span>元</span></div>
                        </div>
                      </div>
                    </vue-seamless-scroll>
                    <div class="lottery-rule-record-no-data-box" v-if="!inviteTaskCommissionRewordObj.inviteTaskRewordList.length">暂无邀请数据~</div>
                  </div>
                </div>
                <div class="invite-cash-task-three-reward-rule-btn-box" @click="isShowPopupInviteRule=true">
                  <img class="task-three-rule-icon" src="./components/assets/task_three_rule_icon.png" alt="" />
                  <span>任务奖规则</span>
                  <img class="task-three-rule-icon" src="./components/assets/task_three_rule_icon.png" alt="" />
                </div>
              </div>
            </div>
            <div class="awards-prize-activity-tab-content-part-box" v-if="tabActive==1">
              <div class="lottery-rule-title">
                <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
                <div>活动奖品</div>
                <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
              </div>
              <div class="lottery-rule-content-bor" style="padding: 0">
                <div class="lottery-exhibit">
                  <div v-for="(el, index) in awardsList" :key="index" class="lottery-exhibit-item">
                    <div class="lottery-exhibit-item-pic-box">
                      <img class="lottery-exhibit-item-pic" :src="el.awardsImgUrl" alt="" />
                    </div>
                    <div :class="{'lottery-exhibit-name-first': el.awardsRank == 1,'lottery-exhibit-name-second': el.awardsRank == 2,'lottery-exhibit-name-third': el.awardsRank > 2,}">{{ el.awardsRankName }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="special-rewards-ranking-tab-content-part-box" v-if="tabActive==2">
              <div class="lottery-rule-title">
                <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
                <div>排行榜</div>
                <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
              </div>
              <div class="special-rewards-ranking-explain-part-bg-box">
                <div class="ranking-explain-text-box">特别奖规则：</div>
                <div class="rewards-ranking-explain-content-text-part-box">
                  <span v-html="inviteCommissionRankObj.rankRuleDescription"></span>
                </div>
              </div>
              <div class="lottery-rule-content-bor special-rewards-ranking-record-part-box">
                <div class="lottery-ranking-header-part-box">
                  <div class="ranking-item-index"></div>
                  <div class="ranking-item-header-name-part-box">昵称</div>
                  <div class="ranking-item-invite-part-box">已邀请数</div>
                  <div class="ranking-item-target-part-box">目标数</div>
                  <div class="ranking-item-differ-part-box">差值</div>
                </div>
                <vue-seamless-scroll :data="inviteCommissionRankObj.inviteRankList" :class-option="inviteScrollRankOption" class="lottery-record lottery-rewards-ranking">
                  <div class="lottery-ranking-item" v-for="(item, index) in inviteCommissionRankObj.inviteRankList" :key="index">
                    <div class="ranking-item-left-part-box">
                      <div class="ranking-item-index">{{ index+1 }}</div>
                      <div class="ranking-item-header-name-part-box">
                        <img class="head-img" alt="" :src="item.avatarUrl" />
                        <span class="ranking-item-name">{{item.nickName}}</span>
                      </div>
                    </div>
                    <div class="ranking-item-right-part-box">
                      <div class="ranking-item-invite-part-box">{{item.invitedCount}}<span>人</span></div>
                      <div class="ranking-item-target-part-box">{{item.targetCount}}<span>人</span></div>
                      <div class="ranking-item-differ-part-box">{{item.diffCount}}</div>
                    </div>
                  </div>
                </vue-seamless-scroll>
                <div class="lottery-rule-record-no-data-box" v-if="!inviteCommissionRankObj.inviteRankList.length">暂无邀请数据~</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 活动规则 -->
      <div class="lottery-rule-container" data-html2canvas-ignore>
        <img class="lottery-rule-pic" src="./components/assets/img12.png" alt="" />
        <div class="lottery-rule lottery-rule-margin">
          <div class="lottery-rule-title">
            <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
            <div>抽签活动规则</div>
            <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
          </div>
          <div class="lottery-rule-content-bor">
            <div class="lottery-rule-cont" v-html="activityInfo.ruleDescription"></div>
          </div>
        </div>
      </div>
      <!-- 中奖记录 -->
      <div v-if="
        activityRaffleInfo.winRecordList &&
        activityRaffleInfo.winRecordList.length
      " class="lottery-rule-container" data-html2canvas-ignore>
        <img class="lottery-rule-pic" src="./components/assets/img12.png" alt="" />
        <div class="lottery-rule lottery-rule-margin">
          <div class="lottery-rule-title">
            <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
            <div>已有 {{ activityRaffleInfo.joinNum }} 人次参与</div>
            <img class="rule-title-icon" src="./components/assets/img2.png" alt="" />
          </div>
          <div class="lottery-rule-content-bor">
            <!-- <div class="lottery-record"> -->
            <vue-seamless-scroll :data="activityRaffleInfo.winRecordList" :class-option="seamlessScrollOption" class="lottery-record">
              <div class="lottery-record-item" v-for="(el, index) in activityRaffleInfo.winRecordList" :key="index">
                <div class="user-head-img-box">
                  <img class="user-head-img" :src="el.avatarUrl" alt="" />
                </div>
                <div class="user-nickname">{{ el.nickName }}</div>
                <div>抽中</div>
                <div class="lottery-record-item-grade">
                  {{ el.awardsRankName }}
                </div>
                <div>“{{ el.awardsName }}”</div>
              </div>
            </vue-seamless-scroll>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal-pop" v-if="showPrize">
      <img src="./components/assets/img8.png" class="lottery-light" alt="" />
      <Transition name="bounce">
        <div class="prize-main prize-mini-raffle" v-if="showPrize2">
          <div class="prize-close" @click="hidePrize">X</div>
          <div class="prize-mini-raffle-content">
            <div v-if="winInfo.isCashPrize" class="prize-msg">恭喜您抽中</div>
            <div v-if="!winInfo.isCashPrize" class="prize-msg">很遗憾，未中奖</div>
            <div v-if="winInfo.isCashPrize" class="prize-title">
              <span v-if="winInfo.amount">{{ winInfo.amount }}元</span>{{ winInfo.awardName }}
            </div>
            <div class="prize-pic-container">
              <img v-if="!winInfo.isCashPrize" src="./components/assets/img13.png" class="prize-pic-260" alt="" />
              <img v-else :src="winInfo.awardImgUrl" class="prize-pic" alt="" />
            </div>
            <div class="prize-btn-box">
              <div class="prize-btn" @click="handleLottery" v-if="!fmtIsWxComBtn"></div>
              <div class="prize-big-btn" @click.stop="clickLotteryResultReceiveBtn" v-if="fmtIsWxComBtn">领取</div>
            </div>
            <div class="receive-award-msg">
              <span v-if="winInfo.isCashPrize">前往<span class="receive-award-link" @click="goLotteryRecords">“我的抽签”</span>查看</span>
            </div>
          </div>
        </div>
        <div class="prize-main prize-mian-bg-part-box" :class="{'invite-prize-invite-part-box':showPrizeType=='inviteReward'}" v-if="showPrizeBg">
          <div class="prize-close" @click="hidePrize">X</div>
          <div v-if="winInfo.isCashPrize" class="prize-msg">{{showPrizeType=='inviteReward'?'恭喜你获得':'恭喜您抽中'}}</div>
          <div v-if="winInfo.isCashPrize" class="prize-title">
            <span v-if="showPrizeType==='inviteReward'">{{winInfo.amount}}元现金奖励</span>
            <span v-if="winInfo.amount&&showPrizeType!=='inviteReward'">{{ winInfo.amount }}元</span>{{ winInfo.awardName }}
          </div>
          <div class="prize-tips-box" v-if="showPrizeType==='inviteReward'">
            <span>该现金会直接发放到您的余额，请注意查收</span>
          </div>
          <div class="prize-pic-container">
            <img src="./components/assets/img10.png" class="prize-pic" alt="" v-if="showPrizeType==='inviteReward'" />
            <img :src="winInfo.awardImgUrl" class="prize-pic" alt="" v-else />
          </div>
          <div class="prize-input-wrap-part-box">
            <div class="prize-input-bg-part-box">
              <div class="prize-input-phone-verify-box" v-if="winInfo.cashPrizeStatus==='toBeVerified'">
                <div class="input-phone-verify-item-box">
                  <div class="phone-verify-item-left-box">
                    <div class="input-lable-text">电话号码</div>
                    <!-- <input class="input-box" placeholder="请输入" maxlength="11" type="tel" v-model="receiveObj.phone" /> -->
                    <mt-field class="input-box" placeholder="请输入" v-model.lazy="receiveObj.phone" :attr="{maxlength:11}" type="tel" disableClear></mt-field>
                  </div>
                </div>
                <div class="input-phone-verify-item-box">
                  <div class="phone-verify-item-left-box">
                    <div class="input-lable-text">验证码</div>
                    <!-- <input class="input-box" placeholder="请输入" maxlength="6" type="tel" v-model="receiveObj.verifyCode" />  -->
                    <mt-field class="input-box" placeholder="请输入" v-model.lazy="receiveObj.verifyCode" :attr="{maxlength:6}" type="tel" disableClear></mt-field>
                  </div>
                  <div class="phone-verify-item-right-box">
                    <div class="phone-verify-btn-box" v-if="verificationFlag" @click.stop="clickReceiveSendCodeBtn">获取</div>
                    <div class="phone-verify-time" v-if="!verificationFlag">{{timeNum}}s</div>
                  </div>
                </div>
              </div>
              <div class="prize-input-img-verify-box" v-if="winInfo.cashPrizeStatus==='toBePicVerified'">
                <div class="input-img-verify-left-box">
                  <div class="input-lable-text">验证码</div>
                  <!-- <input class="input-box" placeholder="请输入" v-model="receiveObj.verifyCode" /> -->
                  <mt-field class="input-box" placeholder="请输入" v-model.lazy="receiveObj.verifyCode" :attr="{maxlength:6}" disableClear></mt-field>
                </div>
                <div class="input-img-verify-right-box">
                  <img class="images-verify" :src="receiveObj.imgVerify">
                  <div class="refresh-btn-box" @click.stop="clickRefreshPicVerifyBtn(1)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="prize-btn-box">
            <div class="prize-big-btn" :class="{'is-disable':fmtReceiveDisable}" @click.stop="clickPrizeReceiveExchangeBtn">{{showPrizeType=='inviteReward'?'领取现金奖励':'提交兑奖资格'}}</div>
          </div>
          <div v-if="winInfo.isCashPrize&&showPrizeType!='inviteReward'" class="receive-award-msg">前往<div class="receive-award-link" @click="goLotteryRecords">“我的抽签”</div>查看</div>
        </div>
      </Transition>
    </div>

    <!-- 海报 -->
    <mt-popup v-model="posterVisible" position="center" class="poster-container" :closeOnClickModal="true">
      <div class="poster-pic-close-box">
        <img class="pic-close-icon" src="./components/assets/img15.png" @click.stop="posterVisible=false" />
      </div>
      <div class="poster-pic-box">
        <img style="width: 100%" :src="posterPicSrc" alt="" />
      </div>
      <div class="poster-bottom-msg">
        <img src="./components/assets/img27.png" class="poster-bottom-msg-icon" alt="" />
        <div>长按保存，分享给好友吧！</div>
      </div>
    </mt-popup>

    <!-- 企微二维码 -->
    <!-- 微信公众号二维码 -->
    <mt-popup v-model="wxcodeVisible" position="center" class="submit-confirm-container" :closeOnClickModal="false">
      <div class="wxcode-main" v-if="conditionType == 2">
        <img class="wxcode-pic" src="./components/assets/img26.jpg" alt="" />
        <div class="confirm-msg">
          <div>请关注<span class="bold">【博士攻略公众号】</span></div>
          <div>再参加活动!</div>
        </div>
      </div>
      <div class="wxcode-main" v-if="conditionType == 1">
        <div class="wxcode-main-qr-code">
          <!-- <vue-qr class="qr-code" :text="qrCodeUrl" :size="350" /> -->
          <img class="qr-code" crossorigin="anonymous" :src="qrCodeUrl + '?' + new Date().getTime()">
        </div>
        <div class="confirm-msg">
          <div>加<span class="bold">企微</span>领红包</div>
          <!-- <div>还可再领一次红包</div> -->
        </div>
      </div>
    </mt-popup>
    <!-- 分享指引 -->
    <mt-popup v-model="shareVisible" position="bottom" class="lottery-info-container">
      <div class="share-pop">
        <div class="share-pop-title">转发到</div>
        <div class="share-list">
          <div class="share-item" @click="wxShareVisible = true">
            <img src="./components/assets/img28.png" class="share-item-img" alt="" />
            <div class="share-item-text">微信好友</div>
          </div>
          <div class="share-item" @click="wxShareVisible = true">
            <img src="./components/assets/img29.png" class="share-item-img" alt="" />
            <div class="share-item-text">朋友圈</div>
          </div>
          <div class="share-item" @click="getPoster">
            <img src="./components/assets/img30.png" class="share-item-img" alt="" />
            <div class="share-item-text">生成海报</div>
          </div>
        </div>
      </div>
    </mt-popup>

    <!-- 微信分享箭头 -->
    <mt-popup v-model="wxShareVisible" position="top" class="wx-share-container">
      <div class="wx-share-main">
        <div class="wx-share-text">
          <div class="wx-share-text-item">
            1、点击右上角<img src="./components/assets/img34.png" alt="" />按钮
          </div>
          <div class="wx-share-text-item">
            2、选择<img src="./components/assets/img32.png" alt="" />或<img src="./components/assets/img33.png" alt="" />转发
          </div>
        </div>
        <img class="wx-share-pic" src="./components/assets/img31.png" alt="" />
      </div>
    </mt-popup>

    <!-- 任务奖励规则 弹窗 -->
    <mt-popup v-model="isShowPopupInviteRule" position="center" class="invite-task-three-reward-rule-container" :closeOnClickModal="false">
      <div class="invite-task-three-reward-rule-close-btn-part-box">
        <img class="images-three-reward-rule-close" src="./components/assets/task_three_rule_close_icon.png" @click="isShowPopupInviteRule = false" />
      </div>
      <div class="invite-task-three-reward-rule-main-bg-part-box">
        <div class="invite-task-three-reward-rule-title-box">奖励规则</div>
        <div class="invite-task-three-reward-rule-content-box">
          <span v-html="inviteTaskCommissionRewordObj.inviteRewordRuleDescription"></span>
        </div>
      </div>
    </mt-popup>

    <!-- 展示提示弹窗 -->
    <mt-popup v-model="isShowCustomPopupToast" position="center" class="page-custom-popup-toast-part-wrap-box">
      <div class="custom-popup-toast-header-part-box">
        <img class="images-toast" src="./components/assets/img25.png" />
      </div>
      <div class="custom-popup-toast-body-part-wrap-box">
        <span>{{customToastConetnt}}</span>
      </div>
      <div class="custom-popup-toast-footer-part-wrap-box">
        <div class="toast-btn-box" @click.stop="isShowCustomPopupToast=false,showShare()">召唤好友</div>
      </div>
    </mt-popup>

  </div>
</template>
<script>
let timer = null;
let timeNum = 60; // 手机验证码限制时间
import wx from "weixin-js-sdk";
// import vueQr from "vue-qr"; // vue2.0 

import {
  wxSign,
  getActivityByActivityCode,
  getActivityRaffleInfo,
  getUserActivityInfo,
  joinRaffle,
  getEnWxLink,
  sendSmsVerifyCode,
  postPicVerifyInfo,
  postUserInviteRewardInfo,
  editUserRaffleDetail,
  getInviteRewordRecordInfo,
  getInviteCommissionRankInfo,
  getActivitySharePosterInfo
} from "@/request/api";

import { unique } from "@/utils/index";

import { mapState } from "vuex";
// import html2canvas from "html2canvas";
import vueSeamlessScroll from "vue-seamless-scroll"; // vue2引入方式
import { grantStatusData } from './components/js/data.js'
export default {
  filters: {
    getGrade (val) {
      let gradeObj = {
        1: "一等奖",
        2: "二等奖",
        3: "三等奖",
        4: "四等奖",
        5: "五等奖",
        6: "六等奖",
        7: "七等奖",
        8: "八等奖",
      };
      return gradeObj[val];
    },
  },
  data () {
    return {
      wxShareVisible: false, // 微信分享指引
      shareVisible: false, // 分享指引
      extRaffleList: [], // 额外抽奖次数邀请方式
      posterContainerShow: false,
      shareBgImgUrl: "", // 海报背景图
      conditionType: "", // 1 企业微信  2 公众号
      qrCodeUrl: "",
      posterPicSrc: "",
      posterVisible: false,
      wxcodeVisible: false,
      isLottery: false, // 抽奖动作
      showPrize: false,
      showPrize2: false,
      showPrizeBg: false,
      showPrizeType: 'userRaffle',  //抽奖类型 userRaffle:用户抽奖，inviteReward:邀请奖励
      winInfo: {}, // 中奖信息
      activityInfo: {
        extRaffleList: []
      }, // 活动基础信息
      activityRaffleInfo: {}, // 抽奖信息
      awardsList: [], // 奖品列表
      prizeOptions: [{
        type: 1,
      }, {
        type: 2,
        name: "5元现金红包",
      }, {
        type: 3,
        name: "多肽焕颜补水面膜",
      }],
      userActivityInfo: {}, // 用户抽奖信息
      currentRaffleTimes: 0,

      inviteTaskList: [],  //邀请任务列表
      userInviteCount: 0,  //累计邀请总数
      inviteRecordList: [],  //中奖记录列表

      statusList: grantStatusData,   //状态列表

      verificationFlag: true, // 是否能发送验证码
      timeNum: timeNum,
      receiveObj: {
        activityCode: "", //活动编码
        wxOpenId: "", //用户微信公众号openId
        activityUserInviteRewardRecordId: 0, //邀请奖励记录id
        phone: "", //手机号，如果是手机号验证必填
        verifyCode: '',  //验证码
        imgVerify: '',  //图形验证-图片
      },

      tabActive: 1,  //0:任务奖励、1:活动奖品、2:排行榜 切换
      inviteTaskSwiperIndex: 0,
      isShowPopupInviteRule: false,  //是否显示任务奖励规则
      inviteTaskCommissionRewordObj: {  //邀请任务-分拥奖励 信息
        inviteRewordRuleDescription: '', //奖励规则 commissionDescription
        invitedTaskCount: 0,  //邀请人数
        invitedTaskAmount: 0, //总奖励金额
        inviteTaskRewordList: [],  //邀请任务-分拥奖励列表
      },
      inviteCommissionRankObj: {  //获取活动邀请分佣信息（分佣规则，排行榜）
        rankRuleDescription: '',  //规则说明
        inviteRankList: [],  //排行榜
      },

      isShowCustomPopupToast: false,   //是否展示提示弹窗
      showCustomPopupType: 0,  //提示弹窗-类型 1:当日抽奖次数用完
      customToastConetnt: '',   //提示弹窗-内容

    };
  },
  computed: {
    ...mapState({
      locationInfo: (state) => state.user.locationInfo,
      userInfo: (state) => state.user.userInfo,
    }),
    seamlessScrollOption () {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    inviteScrollOption () {   //邀请用户
      return {
        step: this.inviteRecordList.length >= 8 ? 1 : 0, // 数值越大速度滚动越快
        limitMoveNum: this.inviteRecordList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        openTouch: false,  //移动端开启 touch 滑动。
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        autoPlay: this.inviteRecordList.length > 8,
      };
    },
    inviteScrollRewordOption () {   //邀请用户-分拥奖励
      return {
        step: this.inviteTaskCommissionRewordObj.inviteTaskRewordList.length >= 10 ? 1 : 0, // 数值越大速度滚动越快
        limitMoveNum: this.inviteTaskCommissionRewordObj.inviteTaskRewordList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        openTouch: false,  //移动端开启 touch 滑动。
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        autoPlay: this.inviteTaskCommissionRewordObj.inviteTaskRewordList.length > 10,
      };
    },
    inviteScrollRankOption () {  ////邀请用户-分拥排行榜
      return {
        step: this.inviteCommissionRankObj.inviteRankList.length >= 10 ? 1 : 0, // 数值越大速度滚动越快
        limitMoveNum: this.inviteCommissionRankObj.inviteRankList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        openTouch: false,  //移动端开启 touch 滑动。
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        autoPlay: this.inviteCommissionRankObj.inviteRankList.length > 10,
      };
    },
    fmtStatusShow () {
      return (status_val, fmt_field) => {
        let status_obj = grantStatusData.find(item => item.value == status_val) || null;
        return status_obj ? status_obj[fmt_field] : '';
      }
    },
    fmtReceiveDisable () {
      if (this.winInfo.cashPrizeStatus == 'toBeVerified') {
        return this.receiveObj.phone.length == 11 && this.receiveObj.verifyCode.length == 6 ? false : true;
      } else if (this.winInfo.cashPrizeStatus == 'toBePicVerified') {
        return this.receiveObj.verifyCode.length == 6 ? false : true;
      } else {
        return false;
      }
    },
    fmtIsWxComBtn () {
      return this.userActivityInfo.lackIdentity.includes("enWx");
    },
  },
  name: "bsgl-home",
  components: { vueSeamlessScroll },  //vueQr, 
  created () {
    this.init();

  },
  methods: {
    handleLaunchFn (e) {
      console.log('handleLaunchFn', e)
    },
    handleErrorFn (e) {
      console.log('handleErrorFn', e)
    },
    showShare () {
      this.$indicator.open({
        text: "海报生成中...",
        spinnerType: "fading-circle",
      });
      getActivitySharePosterInfo({
        activityCode: this.$route.query.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //微信openId
      }).then(res => {
        console.log('分享邀请海报', res)
        if (res.code == 200 && res.data) {
          this.posterPicSrc = `data:image/jpg;base64,${res.data}`
          this.shareVisible = true;
        } else {
          this.$toast({ message: res.message, duration: 2000, })
        }
      }).finally(() => {
        this.$indicator.close();
      })
    },
    getPoster () {
      this.posterVisible = true;
      this.shareVisible = true; // 分享菜单
    },
    init () {
      if (!this.userInfo.wxOpenId) {
        this.$store.dispatch("user/fedLogOut");
        return;
      } else {
        this.handleGetActivityByActivityCode(true);
        this.handleGetActivityRaffleInfo();
        this.wxSdk();
      }
    },
    // 处理邀请奖励当前展示index
    getHandleInviteTaskIndex () {
      let invite_index = this.inviteTaskList.findIndex(item => item.lackCount && !item.activityUserInviteRewardRecordId)
      this.inviteTaskSwiperIndex = invite_index ? invite_index : 0;
    },
    handleGetUserActivityInfo (locationInfo, is_lottery_result = false) {
      // 获取用户活动信息,是否可以抽奖
      let params = {
        wxOpenId: this.userInfo.wxOpenId, //微信openId
        activityCode: this.$route.query.activityCode, //活动编码
        lng: locationInfo ? locationInfo.longitude : "", //经度
        lat: locationInfo ? locationInfo.latitude : "", //维度
      };
      getUserActivityInfo(params).then((res) => {
        console.log('获取用户活动信息', res)
        if (res.code == 200 && res.data) {
          this.userActivityInfo = res.data;
          this.currentRaffleTimes = res.data.currentRaffleTimes;
          if (!res.data.isInRegion) this.$toast({ message: '您不在活动范围之内，谢谢您的参与', duration: 2000, });
          if (!this.activityInfo.enableInviteCommission) this.getHandleSInviteTaskRecordShowData(res.data.userInviteRewardList || [], res.data.userInviteRecordList || []);  //处理邀请展示数据
          console.log('is_lottery_result', is_lottery_result)
          if (!is_lottery_result) {
            if (res.data.lackIdentity.includes("wxOfficial")) {
              this.conditionType = 2;
              this.wxcodeVisible = true;
            }
            this.getIsShowWeComPopupData(res.data.lackIdentity);  //是否显示企业微信弹窗
          }
        } else {
          this.$toast({
            message: res.message,
            position: "middle",
            duration: 1500,
          });
        }
      });
    },
    // 是否显示企业微信弹窗
    getIsShowWeComPopupData (lack_identity) {
      if (lack_identity.includes("enWx")) {
        let params = { activityCode: this.$route.query.activityCode, }; //活动编码
        if (this.$route.query.fromWxOpenId) params.fromUserWxOpenId = this.$route.query.fromWxOpenId; //分享人的id
        // 生成企业微信
        getEnWxLink(params).then((res) => {
          console.log('生成企业微信', res)
          if (res.code == 200) {
            this.qrCodeUrl = res.data.linkUrl;
            this.conditionType = 1;
            this.wxcodeVisible = true;
          }
        });
      }
    },
    // 处理邀请展示数据
    getHandleSInviteTaskRecordShowData (userInviteRewardList = [], userInviteRecordList = []) {
      this.inviteTaskList = userInviteRewardList || [];  //获取邀请列表数据
      this.inviteRecordList = userInviteRecordList || []; //获取邀请记录数据 
      this.userInviteCount = userInviteRewardList.length ? userInviteRewardList[0].actualInviteCount : 0;  //累计邀请总数
      this.getHandleInviteTaskIndex();  //处理邀请奖励当前展示index
    },
    // 获取用户分佣奖励信息
    getPageActivityCommissionRewordData () {
      getInviteRewordRecordInfo({
        activityCode: this.$route.query.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //微信openId
      }).then(res => {
        console.log('获取用户分佣奖励信息', res)
        if (res.code == 200 && res.data) {
          this.inviteTaskCommissionRewordObj = {  //邀请任务-分拥奖励 信息
            inviteRewordRuleDescription: res.data.commissionDescription, //奖励规则 commissionDescription
            invitedTaskCount: res.data.invitedCount,  //邀请人数
            invitedTaskAmount: res.data.amount, //总奖励金额
            inviteTaskRewordList: res.data.invitedList || [],  //邀请任务-分拥奖励列表
          }
        } else {
          this.inviteTaskCommissionRewordObj = {  //邀请任务-分拥奖励 信息
            inviteRewordRuleDescription: '', //奖励规则 commissionDescription
            invitedTaskCount: 0,  //邀请人数
            invitedTaskAmount: 0, //总奖励金额
            inviteTaskRewordList: [],  //邀请任务-分拥奖励列表
          }
        }
      })
    },
    // 获取活动佣金排行榜数据
    getPageActivityCommissionRankData () {
      getInviteCommissionRankInfo({
        activityCode: this.$route.query.activityCode, //活动编码
      }).then(res => {
        console.log('获取活动佣金排行榜数据', res)
        if (res.code == 200 && res.data) {
          this.inviteCommissionRankObj = {  //获取活动邀请分佣信息（分佣规则，排行榜）
            rankRuleDescription: res.data.ruleDescription,  //规则说明
            inviteRankList: res.data.rankList || [],  //排行榜
          }
        } else {
          this.inviteCommissionRankObj = {  //获取活动邀请分佣信息（分佣规则，排行榜）
            rankRuleDescription: '',  //规则说明
            inviteRankList: [],  //排行榜
          }
        }
      })
    },
    // 通过活动编码获取活动参与人数中奖记录
    handleGetActivityRaffleInfo () {
      getActivityRaffleInfo({
        activityCode: this.$route.query.activityCode,
      }).then((res) => {
        if (res.code == 200) {
          this.activityRaffleInfo = res.data;
        }
      });
    },
    // 活动基本信息
    handleGetActivityByActivityCode (isInint) {
      getActivityByActivityCode({
        activityCode: this.$route.query.activityCode,
      }).then((res) => {
        console.log('获取活动信息', res);
        if (res.code == 200) {
          res.data.extRaffleList = res.data.extRaffleList || [];
          this.activityInfo = res.data;
          this.awardsList = res.data.awardsList || [];
          this.shareBgImgUrl = this.activityInfo.shareBgImgUrl;

          if (res.data.extRaffleList && res.data.extRaffleList.length) this.extRaffleList = unique(res.data.extRaffleList || [], "extRaffle");
          if (isInint) this.wxSdk();
        }
      });
    },
    wxSdk () {
      wxSign({
        //调用后端接口
        url: location.href.split("#")[0],
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let data = res.data;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: [
              "getLocation",
              "checkJsApi",
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "wx-open-launch-weapp",
            ], // 必填，需要使用的JS接口列表，目前只用到分享
            openTagList: ["wx-open-launch-weapp",]
          });
          wx.ready(() => {
            // 获取地理位置
            // this.activityInfo // 活动信息是否需要 地理位置限制
            if (this.activityInfo.enableRegionLimit) {
              let locationInfo = sessionStorage.getItem("LOCATION_INFO");
              if (locationInfo) {
                let locationInfoObj = JSON.parse(locationInfo);
                this.$store.dispatch("user/setLocationInfo", locationInfoObj);
                this.handleGetUserActivityInfo(locationInfoObj);
              } else {
                wx.getLocation({
                  type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                  success: (res) => {
                    console.log("地理位置", res);
                    let locationInfo = {
                      latitude: res.latitude,
                      longitude: res.longitude,
                    };
                    this.$store.dispatch("user/setLocationInfo", locationInfo);
                    this.handleGetUserActivityInfo(locationInfo);
                  },
                  fail: function (res) {
                    console.log("定位失败", res);
                  },
                });
              }
            } else {
              // 没有限制参加区域，不用地理位置授权
              this.handleGetUserActivityInfo();
            }
            // let shareUrl = `${window.location.origin}${window.location.pathname}#/?activityCode=${this.activityInfo.activityCode}&fromWxOpenId=${this.userInfo.wxOpenId}`;   //废弃
            let shareUrl = this.activityInfo.activityShareUrl   //接口返回
            console.log(shareUrl, "分享出去的自定义链接", this.activityInfo);
            //需在用户可能点击分享按钮前就先调用
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            wx.updateAppMessageShareData({
              title: this.activityInfo.shareTitle, // 分享标题
              desc: this.activityInfo.shareTxt, // 分享描述
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致    http://test-logon.51czt.com
              imgUrl: this.activityInfo.shareImgUrl, // 分享图标
              success: function () { },
            });

            wx.updateTimelineShareData({
              title: this.activityInfo.shareTitle, // 分享标题
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: this.activityInfo.shareImgUrl, // 分享图标
              success: function () { },
            });
          });

          wx.error(function (res) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            console.log("错误：" + res);
          });
        }
      });
    },
    goLotteryRecords () {
      // 抽奖记录
      this.$router.push({
        name: "LotteryRecords",
        query: JSON.parse(JSON.stringify(this.$route.query)),
      });
    },
    // 关闭抽奖弹窗
    hidePrize () {
      this.showPrize = false;
      this.showPrize2 = false;
      this.showPrizeBg = false;
      this.getIsShowWeComPopupData(this.userActivityInfo.lackIdentity);  //是否显示企业微信弹窗
    },
    randomNum (m, n) {
      let num = Math.floor(Math.random() * (m - n) + n);
      return num;
    },
    hideshowPrize () {
      this.showPrize = false;
    },
    handleLottery () {
      // 抽奖
      this.showPrize = false;
      this.showPrize2 = false;
      this.showPrizeBg = false;
      if (this.activityInfo.activityStatus !== "processing") {  // 未开始或者已经结束
        this.$toast({ message: this.activityInfo.activityStatus === "init" ? "活动未开始，敬请期待！" : "活动已结束，敬请期待下期活动！", position: "middle", duration: 2000, });
        return;
      }
      if (!this.userActivityInfo) {  // 用户不可参与活动
        this.$toast({ message: "活动人数已达上限，无法参与该活动", position: "middle", duration: 2000, });
        return;
      }
      if (!this.userActivityInfo.isInRegion) {   // 未开始或者已经结束
        this.$toast({ message: "您不在活动范围之内，谢谢您的参与", position: "middle", duration: 2000 });
        return;
      }
      if (!this.currentRaffleTimes || this.currentRaffleTimes < 1) {  // 次数已经用完了
        this.$toast({ message: "抽奖次数已用完，邀请好友参与游戏，可增加抽奖机会", position: "middle", duration: 2000, });
        // this.isShowCustomPopupToast = true;  //是否展示提示弹窗
        // this.showCustomPopupType = 1  //提示弹窗-类型 1:当日抽奖次数用完
        // this.customToastConetnt = '呀～今日抽奖次数用完啦！赶快召唤好友参加游戏，增加抽奖机会哦！';   //提示弹窗-内容
        return;
      }
      // if (this.userActivityInfo.lackIdentity.length > 0) return; // 有需要不满足的身份，微信公众号或者企微好友
      let params = {
        fromWxOpenId: this.$route.query.fromWxOpenId, // 分享来源微信id
        activityCode: this.$route.query.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //用户微信openId
        lng: this.locationInfo ? this.locationInfo.longitude : "", //经度
        lat: this.locationInfo ? this.locationInfo.latitude : "", //维度
      };
      this.isLottery = true;
      joinRaffle(params).then((res) => {
        console.log('抽奖', res)
        if (res.code == 200) {
          // 抽奖结果
          this.isLottery = true;
          this.winInfo = res.data;
          setTimeout(() => { this.isLottery = false; this.showPrize = true; }, 1000);
          setTimeout(() => {
            if (res.data.isCashPrize) {
              this.showPrizeType = 'userRaffle'
              this[this.fmtStatusShow(res.data.cashPrizeStatus, 'prizePopField')] = true;
              if (res.data.cashPrizeStatus === 'toBePicVerified') this.clickRefreshPicVerifyBtn();  //刷新图形验证-图片
            } else {
              this.showPrize2 = true;
            }
            this.handleGetUserActivityInfo(this.locationInfo, true);   //获取用户活动信息
          }, 1500);
        } else {
          if (res.code == 200072) {
            setTimeout(() => {
              this.showPrize = true
              this.showPrize2 = true;
              this.handleGetUserActivityInfo(this.locationInfo, true);   //获取用户活动信息
            }, 1500);
          }
          setTimeout(() => {
            this.isLottery = false;
            this.$toast({ message: res.message, position: "middle", duration: 2000, });
          }, 1500);
        }
      }).catch(() => {
        setTimeout(() => { this.isLottery = false; }, 1000);
      });
    },
    // 抽奖结果需要领取按钮弹出企业微信弹窗
    clickLotteryResultReceiveBtn () {
      this.handleGetUserActivityInfo(this.locationInfo);   //获取用户活动信息
    },

    // 切换邀请任务swiper
    changeSwiperInviteTask (index) {
      // console.log('切换邀请任务swiper', index)
      this.inviteTaskSwiperIndex = index
    },
    // 点击邀请活动领取验证按钮
    clickReceiveInviteTaskItemBtn (invite_item) {
      console.log('点击邀请活动领取验证按钮', invite_item)
      if (invite_item.grantStatus === 'toBeVerified' || invite_item.grantStatus === 'toBePicVerified') {
        this.winInfo = {
          activityUserInviteRewardRecordId: invite_item.activityUserInviteRewardRecordId,
          amount: invite_item.rewardAmount,
          cashPrizeStatus: invite_item.grantStatus,
          isCashPrize: true
        }
        this.showPrizeType = 'inviteReward';
        this.showPrize = true;
        this[this.fmtStatusShow(invite_item.grantStatus, 'prizePopField')] = true;
        if (invite_item.grantStatus === 'toBePicVerified') this.clickRefreshPicVerifyBtn();  //刷新图形验证-图片
      } else {
        console.log('邀请活动领取验证按钮-状态：', this.fmtStatusShow(invite_item.grantStatus, 'label'))
      }
    },

    // 点击领取按钮
    clickPrizeReceiveExchangeBtn () {
      if (this.winInfo.cashPrizeStatus !== 'toBePicVerified' && !this.receiveObj.phone) return this.$toast('电话号码不能为空');
      if (!this.receiveObj.verifyCode) return this.$toast('验证码不能为空');
      if (this.showPrizeType === 'inviteReward') {   //邀请奖励
        this.postInviteRewardReceiveData();  //调邀请奖励-领取接口
      } else {   //用户抽奖
        this.postUserRaffleReceiveData();  // 抽奖-领取接口
      }
    },
    // 调邀请奖励-领取接口
    postInviteRewardReceiveData () {
      postUserInviteRewardInfo({
        activityCode: this.$route.query.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //用户微信公众号openId
        activityUserInviteRewardRecordId: this.winInfo.activityUserInviteRewardRecordId, //邀请奖励记录id
        phone: this.receiveObj.phone, //手机号，如果是手机号验证必填
        verifyCode: this.receiveObj.verifyCode, //验证码
      }).then(res => {
        if (res.code == 200) {
          this.$toast({ message: '领取成功', position: 'middle', duration: 2000, })
          this.showPrize = false;
          this[this.fmtStatusShow(this.winInfo.cashPrizeStatus, 'prizePopField')] = false;
        }
      })
    },
    // 抽奖-领取接口
    postUserRaffleReceiveData () {
      editUserRaffleDetail({
        activityCode: this.$route.query.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //用户微信公众号openId
        activityUserRaffleRecordId: this.winInfo.activityUserRaffleRecordId, //抽奖记录id
        phone: this.receiveObj.phone, //手机号，如果是手机号验证必填
        smsVerifyCode: this.receiveObj.verifyCode, //验证码
        pickUpType: '', //提货类型，实物奖品时用到。self：自提，post：邮寄
        consigneeName: '', //收货人，邮寄时必填
        consigneeAddress: '', //收货地址，邮寄时必填
      }).then(res => {
        if (res.code == 200) {
          this.$toast({ message: '领奖验证信息已提交', position: 'middle', duration: 2000, })
          this.showPrize = false;
          this[this.fmtStatusShow(this.winInfo.cashPrizeStatus, 'prizePopField')] = false;
          this.handleGetUserActivityInfo(this.locationInfo);
        }
      })
    },

    // 刷新图形验证
    clickRefreshPicVerifyBtn (is_click = 0) {
      if (!is_click) this.receiveObj.verifyCode = '';
      let biz_id = this.showPrizeType === 'inviteReward' ? this.winInfo.activityUserInviteRewardRecordId : this.winInfo.activityUserRaffleRecordId;
      postPicVerifyInfo({
        activityCode: this.$route.query.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //用户微信公众号openId
        bizId: biz_id, //业务id，如果业务是抽奖，则传抽奖记录id，如果是邀请奖励，则传邀请奖励记录id
        bizType: this.showPrizeType, //业务类型，userRaffle:用户抽奖，inviteReward:邀请奖励
      }).then(res => {
        let blob = new Blob([res], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        console.log('图片', imageUrl)
        this.receiveObj.imgVerify = imageUrl;
      })
    },

    // 获取手机号验证码
    clickReceiveSendCodeBtn () {
      if (!this.receiveObj.phone) return this.$toast('电话号码不能为空');
      if (!this.verificationFlag) return;
      sendSmsVerifyCode({
        activityCode: this.$route.query.activityCode,  //活动编码
        wxOpenId: this.userInfo.wxOpenId, //用户微信opendId, 必填
        phone: this.receiveObj.phone, //手机号，必填
      }).then(res => {
        if (res.code == 200) {
          this.$toast('验证码已发送至您的手机，请注意查收');
          this.verificationFlag = false;
          timer = setInterval(() => {
            this.timeNum -= 1;
            if (this.timeNum < 0) {
              this.timeNum = timeNum;
              this.verificationFlag = true;
              clearInterval(timer);
            }
          }, 1000);
        }
      })
    },


    // 点击切换任务奖励、活动奖品按钮 0:任务奖励、1:活动奖品
    clickChangeTabActiveBtn (tab_id) {
      this.tabActive = tab_id
      if (this.tabActive == 2) {   //特别奖
        this.getPageActivityCommissionRankData();  //获取活动佣金排行榜数据
      } else {
        if (this.tabActive == 0 && this.activityInfo.enableInviteCommission) {
          this.getPageActivityCommissionRewordData();  //获取用户分佣奖励信息
        } else {
          this.handleGetUserActivityInfo(this.locationInfo);
        }
      }
    },

  },
  mounted () { },

};
</script>
<style lang="scss">
.container {
  height: 100%;
  overflow: auto;
  background: linear-gradient(180deg, #9ff4ff 0%, #079cb0 100%);
  padding-bottom: 40px;
  .lottery-main {
    width: 100%;
    height: 988px;
    background: url('./components/assets/img1.png') no-repeat center;
    background-size: 100% 100%;
    position: relative;
    .my-lottery {
      position: absolute;
      top: 100px;
      right: 0px;
      width: 72px;
      height: 232px;
      background: url('./components/assets/img3.png') no-repeat center;
      background-size: 100%;
    }
    .lottery-qt {
      position: absolute;
      top: 240px;
      left: 215px;
      width: 328px;
      height: 600px;
      background: url('./components/assets/img7.png') no-repeat center;
      background-size: 100%;
      .lottery-btn {
        width: 145px;
        height: 145px;
        position: absolute;
        top: 315px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
      }
      .lucky-num {
        position: absolute;
        top: 464px;
        left: 50%;
        transform: translateX(-50%);
        width: 164px;
        height: 45px;
        line-height: 45px;
        z-index: 4;
        color: #7a140e;
        font-size: 24px;
        text-align: center;
        padding-right: 6px;
        em {
          font-size: 28px;
          margin-right: 4px;
        }
      }
    }
    .lottery-qt-active {
      animation: myMove3 0.4s ease infinite;
      -webkit-animation: myMove3 0.4s ease infinite;
    }
  }
}
.modal-pop {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 65px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(30, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 99;
  .lottery-light {
    width: 150vh;
    height: 150vh;
    animation: myMove4 8s linear infinite;
    opacity: 0.3;
  }
  .prize-main {
    width: 573px;
    position: absolute;
    left: 88px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 180px;
    padding-bottom: 160px;
    overflow: hidden;
    z-index: 991;
    &.prize-mini-raffle {
      height: 913px;
      top: 200px;
      background-image: url('./components/assets/img9.png');
      background-size: 100% 913px;
    }
    .prize-mini-raffle-content {
      width: 100%;
      height: 100%;
      display: flex;
      display: flex;
      align-content: space-between;
      flex-direction: column;
      justify-content: space-between;
    }
    .prize-msg {
      color: #ffffff;
      font-size: 32px;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .prize-title {
      color: #ffffff;
      font-size: 52px;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
    }
    .prize-pic-container {
      flex: 1;
      min-height: 300px;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .prize-pic {
        width: 245px;
        height: 233px;
      }
      .prize-pic-260 {
        width: 260px;
        height: 260px;
      }
    }
    .prize-btn-box {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .prize-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #dcffec 8%, #f7f6cd 100%);
        height: 109px;
        width: 427px;
        border-radius: 36px;
        color: #4ccae2;
        font-size: 32px;
        font-weight: 700;
        background: url('./components/assets/img14.png') center no-repeat;
        background-size: 100% 100%;
      }
      .prize-big-btn {
        width: 425px;
        height: 68px;
        border-radius: 50px;
        background: linear-gradient(180deg, #dcffec 8%, #f7f6cd 100%);
        font-family: FZDaBiaoSong-B06S;
        font-size: 32px;
        font-weight: bold;
        line-height: 68px;
        text-align: center;
        color: #4ccae2;
        position: relative;
        &.is-disable {
          background: linear-gradient(180deg, #ffffff 8%, #d0e8ed 100%);
          color: #bfdde2;
        }
        &:not(.is-disable):hover,
        &:not(.is-disable):active {
          background: linear-gradient(180deg, #aeffd3 8%, #fffdbc 100%);
        }
        &::after {
          content: '';
          width: 72px;
          height: 108px;
          background-image: url('./components/assets/img_big_btn_left_icon.png');
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          left: -2px;
          top: -16px;
        }
      }
    }
    .receive-award-msg {
      display: flex;
      justify-content: center;
      color: #c5f5ff;
      font-size: 26px;
      position: absolute;
      left: 50%;
      bottom: 90px;
      transform: translateX(-50%);
      .receive-award-link {
        text-decoration: underline;
      }
    }
  }
  // 需要-验证信息
  .prize-mian-bg-part-box {
    height: 1026px;
    top: 120px;
    background-image: url('./components/assets/img_prize_big_bg.png');
    background-size: 100% 973px;
    background-position: center 52px;
    .prize-tips-box {
      font-size: 22px;
      color: #94dfee;
      text-align: center;
      margin-top: 10px;
    }
    .prize-pic-container {
      height: 240px;
      margin-top: 30px;
    }
    .prize-input-wrap-part-box {
      width: 425px;
      height: 140px;
      margin: 50px auto 0px;
      display: flex;
      align-items: flex-end;
      .prize-input-bg-part-box {
        width: 100%;
        // height: 140px;
        border-radius: 34px;
        background: linear-gradient(
          180deg,
          #007b93 0%,
          rgba(227, 250, 255, 0) 50%,
          #007b93 100%
        );
        box-shadow: 0px 4px 4px 0px #00000040 inset;
        .input-lable-text {
          width: 105px;
          font-size: 26px;
          color: #f7f6cd;
          margin-right: 10px;
          text-align: right;
        }
        .input-box {
          flex: 1;
          height: 100%;
          // font-size: 26px;
          background: transparent;
          border: none;
          color: rgba(255, 255, 255, 1);

          &:focus {
            outline-style: none;
          }
          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .prize-input-phone-verify-box {
        padding: 14px 16px;
        box-sizing: border-box;
        .input-phone-verify-item-box {
          padding: 10px 0px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .phone-verify-item-left-box {
          flex: 1;
          display: flex;
          align-items: center;
          .input-box {
            width: 200px;
          }
        }
        .phone-verify-item-right-box {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          font-size: 26px;
          margin-left: 10px;
          .phone-verify-btn-box {
            color: #dff7cd;
            text-decoration: underline;
          }
          .phone-verify-time {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .prize-input-img-verify-box {
        padding: 14px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .input-img-verify-left-box {
          flex: 1;
          display: flex;
          align-items: center;
          .input-lable-text {
            width: auto;
          }
          .input-box {
            width: 90px;
          }
        }
        .input-img-verify-right-box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: 10px;
          .images-verify {
            width: 115px;
            height: 50px;
          }
          .refresh-btn-box {
            width: 40px;
            height: 40px;
            margin-left: 15px;
            background-image: url('./components/assets/img_verify_refresh_icon.png');
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }
      }
    }

    .prize-btn-box {
      margin-top: 30px;
      .prize-big-btn {
        width: 425px;
        height: 68px;
        border-radius: 50px;
        background: linear-gradient(180deg, #dcffec 8%, #f7f6cd 100%);
        font-family: FZDaBiaoSong-B06S;
        font-size: 32px;
        font-weight: bold;
        line-height: 68px;
        text-align: center;
        color: #4ccae2;
        position: relative;
        &.is-disable {
          background: linear-gradient(180deg, #ffffff 8%, #d0e8ed 100%);
          color: #bfdde2;
        }
        &:not(.is-disable):hover,
        &:not(.is-disable):active {
          background: linear-gradient(180deg, #aeffd3 8%, #fffdbc 100%);
        }
        &::after {
          content: '';
          width: 72px;
          height: 108px;
          background-image: url('./components/assets/img_big_btn_left_icon.png');
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          left: -2px;
          top: -16px;
        }
      }
    }

    &::before {
      content: '';
      width: 166px;
      height: 166px;
      background-image: url('./components/assets/img_prize_raffle_title.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .invite-prize-invite-part-box {
    height: 1087px;
    background-size: 100% 1032px;
    &::before {
      content: '';
      width: 166px;
      height: 166px;
      background-image: url('./components/assets/img_prize_invite_title.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
@keyframes myMove {
  0% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@-webkit-keyframes myMove {
  0% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}
@keyframes myMove2 {
  0% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
  }
}

@-webkit-keyframes myMove2 {
  0% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
  }
}

@keyframes myMove3 {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes myMove3 {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes myMove4 {
  0% {
    /*rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转)*/
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes myMove4 {
  0% {
    /*rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转)*/
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.prize-close {
  height: 50px;
  width: 50px;
  border: 2px solid #fff1d3;
  border-radius: 25px;
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff1d3;
  z-index: 100;
  background: #4ebfd5;
}
.prize-close:before {
  content: '';
  position: absolute;
  left: 24px;
  bottom: -26px;
  width: 2px;
  height: 26px;
  background: #fff1d3;
}
.lottery-rule-container {
  padding: 0 26px;
  .lottery-rule-margin {
    margin-top: -40px;
  }
  &:first-child {
    .lottery-rule {
      margin-top: -40px;
    }
  }
  .lottery-rule {
    position: relative;
    padding: 20px;
    background: #f7f6cd;
    box-shadow: 0px 0px 12px 0px #ffffff inset;
    border-radius: 36px;
    .lottery-rule-item {
      padding: 0 16px 0 28px;
      height: 72px;
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #4ebfd5;
      box-shadow: 0px -8px 8px 0px #2292a8 inset,
        0px 8px 8px 0px #ffffff4d inset;
      color: #fff;
      .share-left {
        display: flex;
        align-items: center;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .lottery-rule-share-icon {
        height: 28px;
        width: 28px;
        margin-right: 12px;
      }
    }
    .lottery-rule-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4ebfd5;
      font-weight: 700;
      font-size: 36px;
      height: 50px;
      .rule-title-icon {
        width: 31px;
        height: 26px;
        margin: 0 40px;
      }
    }
    .lottery-rule-content-bor {
      margin-top: 20px;
      border: 4px solid #dcda86;
      border-radius: 20px;
      background: #4ebfd5;
      box-shadow: 0px 0px 32px 0px #3594a9 inset;
      color: #fff;
      padding: 20px;
      p {
        margin-bottom: 12px;
        line-height: 36px;
        font-size: 28px;
      }
    }
  }
  .lottery-rule-pic {
    width: 100%;
  }
}
.lottery-exhibit {
  overflow: hidden;
  display: flex;
  flex-flow: wrap;
  max-height: 620px;
  overflow: auto;
  .lottery-exhibit-item {
    width: 33.3%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lottery-exhibit-item-pic-box {
      padding: 24px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      .lottery-exhibit-item-pic {
        width: 20vw;
        height: 20vw;
      }
    }
    .lottery-exhibit-name-first {
      background-image: linear-gradient(
        to bottom,
        rgba(244, 195, 67, 1),
        rgba(255, 248, 228, 1),
        rgba(244, 195, 67, 1)
      );
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      font-size: 27px;
      text-align: center;
      margin-top: 20px;
      font-weight: 700;
      position: relative;
    }
    .lottery-exhibit-name-first:before {
      content: '';
      position: absolute;
      height: 27px;
      width: 18px;
      left: -20px;
      top: 8px;
      background: url('./components/assets/img18.png') center no-repeat;
      background-size: 100% 100%;
    }
    .lottery-exhibit-name-first:after {
      content: '';
      position: absolute;
      height: 27px;
      width: 18px;
      right: -20px;
      top: 8px;
      background: url('./components/assets/img19.png') center no-repeat;
      background-size: 100% 100%;
    }
    .lottery-exhibit-name-second {
      background-image: linear-gradient(
        to bottom,
        rgba(178, 211, 221, 1),
        rgba(241, 252, 255, 1),
        rgba(178, 211, 221, 1)
      );
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      font-size: 27px;
      text-align: center;
      margin-top: 20px;
      font-weight: 700;
      position: relative;
    }
    .lottery-exhibit-name-second:before {
      content: '';
      position: absolute;
      height: 27px;
      width: 18px;
      left: -20px;
      top: 8px;
      background: url('./components/assets/img20.png') center no-repeat;
      background-size: 100% 100%;
    }
    .lottery-exhibit-name-second:after {
      content: '';
      position: absolute;
      height: 27px;
      width: 18px;
      right: -20px;
      top: 8px;
      background: url('./components/assets/img21.png') center no-repeat;
      background-size: 100% 100%;
    }
    .lottery-exhibit-name-third {
      background-image: linear-gradient(
        to bottom,
        rgba(214, 128, 101, 1),
        rgba(255, 240, 235, 1),
        rgba(214, 128, 101, 1)
      );
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      font-size: 27px;
      text-align: center;
      margin-top: 20px;
      font-weight: 700;
      position: relative;
    }
    .lottery-exhibit-name-third:before {
      content: '';
      position: absolute;
      height: 27px;
      width: 18px;
      left: -20px;
      top: 8px;
      background: url('./components/assets/img22.png') center no-repeat;
      background-size: 100% 100%;
    }
    .lottery-exhibit-name-third:after {
      content: '';
      position: absolute;
      height: 27px;
      width: 18px;
      right: -20px;
      top: 8px;
      background: url('./components/assets/img23.png') center no-repeat;
      background-size: 100% 100%;
    }
  }
}
.lottery-record {
  max-height: 600px;
  overflow: auto;
  .lottery-record-item {
    display: flex;
    align-items: center;
    height: 50px;
    .user-head-img-box {
      .user-head-img {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin-right: 8px;
      }
    }
    .user-nickname {
      margin-right: 30px;
      width: 130px;
      overflow: hidden;
      white-space: nowrap;
    }
    .lottery-record-item-grade {
      font-weight: 700;
      font-size: 28px;
      margin-right: 8px;
    }
  }
}

.lottery-rule-cont {
  * {
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    margin: 0 !important;
    line-height: 30px;
  }
}
.submit-confirm-container {
  border-radius: 36px;
  .wxcode-main {
    width: 75vw;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wxcode-main-qr-code {
      height: 350px;
      width: 350px;
      .qr-code {
        height: 100%;
        width: 100%;
      }
    }
    .confirm-msg {
      margin-top: 20px;
      color: #2f2f2f;
      font-size: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    // .confirm-footer {
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   margin-top: 40px;
    //   .confirm-sure {
    //     display: flex;
    //     align-items: center;
    //     background: #f94920;
    //     height: 64px;
    //     padding: 0 48px;
    //     border-radius: 32px;
    //     color: #fff;
    //     font-size: 28px;
    //   }
    // }
  }
  // .submit-confirm-main {
  //   width: 58vw;
  //   padding: 40px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   .confirm-icon {
  //     height: 56px;
  //     width: 56px;
  //   }
  //   .confirm-msg {
  //     margin-top: 40px;
  //     color: #2f2f2f;
  //     font-size: 28px;
  //   }
  //   .confirm-footer {
  //     width: 100%;
  //     display: flex;
  //     justify-content: space-between;
  //     margin-top: 60px;
  //     .confirm-cancel {
  //       display: flex;
  //       align-items: center;
  //       border: 2px solid #2f2f2f;
  //       height: 64px;
  //       padding: 0 48px;
  //       border-radius: 32px;
  //       color: #2f2f2f;
  //       font-size: 28px;
  //     }
  //     .confirm-sure {
  //       display: flex;
  //       align-items: center;
  //       background: #f94920;
  //       height: 64px;
  //       padding: 0 48px;
  //       border-radius: 32px;
  //       color: #fff;
  //       font-size: 28px;
  //     }
  //   }
  // }
}
.wxcode-pic {
  height: 260px;
  width: 260px;
}
.poster-container {
  background: rgba(0, 0, 0, 0);
  .poster-pic-close-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .pic-close-icon {
      width: 48px;
      height: 72px;
    }
  }
  .poster-pic-box {
    // width: 80vw;
    width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.share-btn {
  color: #fff;
  font-size: 26px;
  height: 48px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: linear-gradient(180deg, #ffe589 0%, #f76c27 100%);
  box-shadow: 0px 2px 8px 0px #0000001a;
}
.wx-open-launch-weapp-box {
  width: 120px;
  height: 48px;
}
.share-wx-user {
  position: absolute;
  left: 24px;
  top: 24px;
  // background: #fff;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  border-radius: 0 0 10px 0;
  color: #fff;
  font-weight: 700;
  font-size: 28px;
  .share-header-img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 8px;
  }
}
.poster-bottom-msg {
  // height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
  font-weight: 700;
  .poster-bottom-msg-icon {
    height: 40px;
    width: 40px;
    animation: myMove5 1.5s ease infinite;
    -webkit-animation: myMove5 2s ease infinite;
  }
}
@keyframes myMove5 {
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}
.lottery-info-container {
  border-radius: 24px 24px 0 0;
  .share-pop {
    max-height: 90vh;
    overflow: auto;
    width: 100vw;
    padding: 30px 30px 72px 30px;
    .share-pop-title {
      color: #222;
      font-weight: 700;
      font-size: 30px;
    }
    .share-list {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .share-item {
        width: 33.3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .share-item-img {
          height: 80px;
          width: 80px;
        }
        .share-item-text {
          margin-top: 24px;
          color: #222;
          font-size: 28px;
        }
      }
    }
  }
}
.wx-share-container {
  background: rgba(0, 0, 0, 0);
}
.wx-share-main {
  width: 100vw;
  display: flex;
  padding: 0 24px 24px 24px;
  // justify-content: center;
}
.wx-share-text {
  width: 400px;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  padding-top: 250px;
}
.wx-share-text-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
  img {
    height: 40px;
    width: 40px;
    margin: 0 10px;
  }
}
.wx-share-pic {
  position: absolute;
  left: 420px;
  top: 0;
  width: 300px;
  height: 300px;
}
// 小程序测肤跳转小程序那妞样式
.detect-btn {
  position: relative;
  .wx-open-launch-weapp-box {
    width: 100%;
    height: 100%;
    background: transparent !important;
    position: absolute;
    top: 0;
    left: 0;
  }
}
// 新-页面内容信息
.page-lottery-rule-content-part-wrap-box {
  margin-top: -30px;
  position: relative;
  z-index: 2;

  /* 增加抽奖次数规则 */
  .lottery-enable-ext-raffle-box {
    .lottery-rule {
      padding: 30px 20px;
    }
  }
  /* 新的邀请奖励、抽奖任务、特别奖励(排行榜) */
  .lottery-rule-invite-cash-prize-task-part-box {
    height: 1205px;
    padding: 0px 0px !important;
    box-shadow: none;
    .invite-cash-prize-task-tab-header-part-box {
      height: 115px;
      border-radius: 36px 36px 0px 0px;
      box-shadow: 0px 0px 12px 0px #ffffff inset;
      border-bottom: 2px solid #e5e39f;
      display: flex;
      align-items: center;
      justify-content: center;
      .cash-prize-task-tab-content-box {
        // width: 304px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        border: 3px solid #e5e39f;
        .cash-prize-task-tab-item {
          width: 125px;
          height: 50px;
          background: #f7f6cd;
          box-shadow: 0px 8px 8px 0px #ffffff inset,
            0px -2px 6px 0px #bdba41 inset;
          font-size: 26px;
          color: #4ebfd5;
          display: flex;
          align-items: center;
          justify-content: center;
          &.is-selected {
            background: #4dcda0;
            box-shadow: 0px 2px 8px 0px rgba(104, 16, 0, 0.4) inset;
            border-right: 3px solid #e5e39f;
            border-left: 3px solid #e5e39f;
            color: #ffffff;
          }
          &:first-child {
            border-radius: 40px 0px 0px 40px;
            &.is-selected {
              border-left: none;
            }
          }
          &:last-child {
            border-radius: 0px 40px 40px 0px;
            &.is-selected {
              border-right: none;
            }
          }
        }
      }
    }
    .invite-cash-prize-task-tab-body-part-wrap-box {
      height: 1091px;
      padding: 30px 0px 30px;
      box-shadow: 0px 0px 12px 0px #ffffff inset;
      border-radius: 0px 0px 36px 36px;
      .lottery-rule-record-no-data-box {
        width: 100%;
        height: 480px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        color: rgba(255, 255, 255, 0.6);
      }
      // 任务奖励
      .invite-cash-task-tab-content-part-box {
        width: 100%;
        height: 100%;
        .lottery-rule-invite-cash-people-num-box {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding: 20px 20px 30px;
          box-sizing: border-box;
          span {
            font-size: 20px;
            color: #4ebfd5;
          }
          .people-num-box {
            position: relative;
            margin: 0px 10px;
            .people-num {
              background: linear-gradient(
                180deg,
                #4dcda0 0%,
                #fff8e4 50%,
                #4dcda0 100%
              );
              -webkit-text-stroke: 1px #4dcda0;
              -webkit-background-clip: text;
              background-clip: text;
              font-family: DINCond-Black;
              font-size: 64px;
              font-weight: bold;
              line-height: 64px;
              text-align: left;
              color: transparent;
              margin: 0px 33px;
            }
            &::before {
              content: '';
              width: 33px;
              height: 33px;
              position: absolute;
              left: 10px;
              bottom: 0px;
              background-image: url('./components/assets/img-people-num-left.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
            &::after {
              content: '';
              width: 33px;
              height: 33px;
              position: absolute;
              right: 5px;
              bottom: 0px;
              background-image: url('./components/assets/img-people-num-right.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .lottery-rule-record-part-box {
          min-height: 500px;
          margin: 0px 20px 0px;
          .lottery-record {
            max-height: 500px !important;
          }
          .lottery-record-item {
            width: 100%;
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;
            color: #ffffff;
            .record-item-left-part-box {
              display: flex;
              align-items: center;
              flex: 1;
              .record-item-index {
                width: 40px;
              }
              .record-item-header-name-part-box {
                width: 260px;
                display: flex;
                align-items: center;
                padding-right: 10px;
                box-sizing: border-box;
                .head-img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  border: 1px solid #ffffff;
                  margin-right: 12px;
                }
                .record-item-name {
                  width: 190px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .record-item-right-part-box {
              opacity: 0.8;
              display: flex;
              align-items: center;
              .record-item-time-part-box {
                width: 260px;
                padding-right: 15px;
                box-sizing: border-box;
              }
              .record-item-bonus-part-box {
                width: 70px;
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
        // v2版本的任务奖励(邀请奖励)样式
        .invite-cash-task-two-version-body-part-box {
          width: 100%;
          height: 100%;
          .lottery-rule-invite-cash-task-box {
            height: 338px;
            padding: 20px 0px 0px;
            box-sizing: border-box;
            .lottery-rule-invite-cash-task-data-box {
              height: 264px;
              .mint-swipe {
                padding: 0px 30px;
              }
              .mint-swipe-item {
                background: linear-gradient(
                  270deg,
                  #fffee1 0%,
                  rgba(255, 254, 225, 0) 100%
                );
                border-radius: 16px;
                overflow: hidden;
              }
              .invite-cash-task-list-item-box {
                width: 100%;
                height: 100%;
                border-radius: 16px;
                padding: 0px 30px 0px 45px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .task-list-item-left-box {
                  flex: 1;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  padding: 40px 0px;
                  box-sizing: border-box;
                  .text-task-title {
                    font-size: 36px;
                    font-weight: bold;
                    -webkit-text-stroke: 1px #5fa5b2;
                    -webkit-background-clip: text;
                    background-clip: text;
                    font-family: Alibaba PuHuiTi;
                    text-align: left;
                    color: transparent;
                    line-height: 46px;
                    b {
                      font-size: 48px;
                      color: #5fa5b2;
                      font-weight: bold;
                      margin: 0px 5px;
                    }
                  }
                  .text-task-tips {
                    font-size: 24px;
                    color: #5fa5b2;
                    margin-top: 15px;
                  }
                  .task-list-item-btn-box {
                    margin-top: 30px;
                    .task-item-btn {
                      width: 160px;
                      height: 52px;
                      border-radius: 40px;
                      background: linear-gradient(
                        180deg,
                        #ffe589 0%,
                        #f76c27 100%
                      );
                      box-shadow: 0px 2px 8px 0px #0000001a;
                      font-size: 26px;
                      color: #ffffff;
                      text-align: center;
                      line-height: 52px;
                      &.receive {
                        background: #e7cdae;
                        box-shadow: none;
                      }
                      &.lack {
                        opacity: 0.4;
                      }
                    }
                  }
                }
                .task-list-item-right-box {
                  width: 280px;
                  height: 256px;
                  border-radius: 16px;
                  background-image: url('./components/assets/img_task_item_bg_big.png');
                  background-position: 0px 0px;
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 25px 50px 82px 58px;
                  box-sizing: border-box;
                  font-size: 68px;
                  color: #fffac7;
                  font-weight: bold;
                }
              }
            }
            .lottery-rule-invite-cash-task-slide-box {
              width: 100%;
              height: 20px;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              margin-top: 20px;
              .invite-cash-task-slide-bar {
                width: 100px;
                height: 8px;
                border-radius: 20px;
                background: #ebe9b0;
                overflow: hidden;
                display: flex;
                align-items: center;
                .task-slide-line {
                  height: 6px;
                  border-radius: 20px;
                  background: #4ebfd5;
                }
              }
            }
          }
        }
        // v3版本的任务奖励(邀请奖励)样式
        .invite-cash-task-three-version-body-part-box {
          width: 100%;
          height: 100%;
          padding: 0px 20px;
          box-sizing: border-box;
          position: relative;
          .invite-cash-task-three-version-body-data-box {
            width: 100%;
            height: 100%;
            .task-three-version-invite-info-part-box {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-around;
              padding: 0px 40px 35px;
              box-sizing: border-box;
              margin-top: 45px;
              .task-three-version-invite-item-box {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .task-three-invite-num-bg-part-box {
                  min-width: 184px;
                  height: 184px;
                  border-radius: 16px;
                  background: #fffee2;
                  font-size: 84px;
                  font-family: DINCond-Black;
                  color: #ff6937;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  span {
                    font-size: 40px;
                  }
                }
                .text-task-three-tips {
                  font-size: 32px;
                  color: #4ebfd5;
                  margin-top: 20px;
                }
              }
            }
            .lottery-rule-record-part-box {
              min-height: 658px;
              margin: 0px 0px;
              padding-top: 15px;
              padding-bottom: 0px;
              box-shadow: 0px 0px 32px 0px #3594a9 inset;
              .lottery-record-task-three-header-part-box {
                height: 40px;
                font-size: 24px;
                color: #70dff4;
                font-weight: 700;
                display: flex;
                align-items: center;
                .record-item-index {
                  width: 40px;
                  height: 100%;
                }
                .record-item-header-name-part-box {
                  flex: 1;
                  height: 100%;
                  padding-right: 10px;
                  box-sizing: border-box;
                }
                .record-item-time-part-box {
                  width: 260px;
                  height: 100%;
                  padding-right: 15px;
                  box-sizing: border-box;
                }
                .record-item-bonus-part-box {
                  width: 75px;
                  height: 100%;
                }
              }
              .lottery-record {
                max-height: 580px !important;
              }
            }
          }

          // 任务奖励规则按钮
          .invite-cash-task-three-reward-rule-btn-box {
            width: 55px;
            min-height: 218px;
            background: #fffedb;
            border-radius: 20px 0px 0px 20px;
            box-shadow: 6px 6px 10px 0px #fffff1 inset,
              -4px -4px 4px 0px #dddb87 inset;
            padding: 20px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 20px;
            right: 0px;
            .task-three-rule-icon {
              width: 14px;
              height: 14px;
            }
            span {
              font-size: 30px;
              color: #d3d075;
              line-height: 32px;
              text-align: center;
              margin: 10px 0px 8px;
            }
          }
        }
      }
      // 活动奖品
      .awards-prize-activity-tab-content-part-box {
        width: 100%;
        height: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .lottery-rule-content-bor {
          width: 100%;
          flex: 1;
          .lottery-exhibit {
            max-height: 950px;
            padding: 10px 0px;
          }
        }
      }
      // 特别奖励(排行榜)
      .special-rewards-ranking-tab-content-part-box {
        width: 100%;
        height: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .special-rewards-ranking-explain-part-bg-box {
          width: 654px;
          height: 263px;
          border-radius: 16px;
          background: linear-gradient(
            180deg,
            #fffee2 0%,
            rgba(255, 254, 226, 0) 100%
          );
          overflow: hidden;
          position: relative;
          padding: 20px 15px 5px;
          box-sizing: border-box;
          margin-top: 30px;
          .ranking-explain-text-box {
            width: 100%;
            padding: 0px 15px 5px;
            box-sizing: border-box;
            font-size: 24px;
            font-weight: 700;
            color: #5fa5b2;
          }
          .rewards-ranking-explain-content-text-part-box {
            height: 200px;
            padding: 5px 15px 0px;
            box-sizing: border-box;
            overflow-y: auto;
            font-size: 24px;
            color: #5fa5b2;
            span {
              white-space: pre-wrap;
            }
          }

          &::after {
            content: '';
            width: 100%;
            height: 28px;
            background: linear-gradient(
              360deg,
              #fffee2 17%,
              rgba(255, 254, 226, 0) 100%
            );
            position: absolute;
            left: 0px;
            bottom: 0px;
          }
        }
        .special-rewards-ranking-record-part-box {
          width: 100%;
          min-height: 658px;
          margin: 30px 0px 0px;
          padding-bottom: 0px;
          box-shadow: 0px 0px 32px 0px #3594a9 inset;
          .lottery-ranking-header-part-box {
            height: 50px;
            padding-bottom: 10px;
            box-sizing: border-box;
            font-size: 24px;
            color: #70dff4;
            font-weight: bold;
            display: flex;
            align-items: center;
            .ranking-item-index {
              width: 40px;
              height: 100%;
            }
            .ranking-item-header-name-part-box {
              flex: 1;
              height: 100%;
              display: flex;
              align-items: center;
              padding-right: 10px;
              box-sizing: border-box;
            }
            .ranking-item-invite-part-box {
              width: 125px;
              height: 100%;
              padding-right: 10px;
              box-sizing: border-box;
            }
            .ranking-item-target-part-box {
              width: 102px;
              height: 100%;
              padding-right: 10px;
              box-sizing: border-box;
            }
            .ranking-item-differ-part-box {
              width: 70px;
              height: 100%;
            }
          }
          .lottery-rewards-ranking {
            max-height: 568px !important;
            .lottery-ranking-item {
              width: 100%;
              height: 58px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 24px;
              color: #ffffff;
              .ranking-item-left-part-box {
                display: flex;
                align-items: center;
                flex: 1;
                .ranking-item-index {
                  width: 40px;
                }
                .ranking-item-header-name-part-box {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  padding-right: 10px;
                  box-sizing: border-box;
                  .head-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #ffffff;
                    margin-right: 12px;
                  }
                  .ranking-item-name {
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
              .ranking-item-right-part-box {
                opacity: 0.8;
                display: flex;
                align-items: center;
                .ranking-item-invite-part-box {
                  width: 125px;
                  height: 100%;
                  padding-right: 10px;
                  box-sizing: border-box;
                  span {
                    font-size: 20px;
                  }
                }
                .ranking-item-target-part-box {
                  width: 102px;
                  height: 100%;
                  padding-right: 10px;
                  box-sizing: border-box;
                  span {
                    font-size: 20px;
                  }
                }
                .ranking-item-differ-part-box {
                  width: 70px;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* v3版本的任务奖励(邀请奖励) 奖励规则弹窗 样式 */
.invite-task-three-reward-rule-container {
  background: transparent;
  .invite-task-three-reward-rule-main-bg-part-box {
    width: 622px;
    min-height: 240px;
    max-height: 80vh;
    background: #f7f6cd;
    border: 2px solid #8f875f;
    border-radius: 36px;
    padding: 30px 15px 40px;
    box-sizing: border-box;
    .invite-task-three-reward-rule-title-box {
      padding: 10px 0px 30px;
      box-sizing: border-box;
      font-size: 28px;
      color: #2f2f2f;
      font-weight: 700;
      text-align: center;
    }
    .invite-task-three-reward-rule-content-box {
      min-height: 100px;
      max-height: calc(80vh - 140px);
      padding: 0px 10px 0px 20px;
      box-sizing: border-box;
      overflow-y: auto;
      font-size: 28px;
      color: #2f2f2f;
      line-height: 42px;
      span {
        white-space: pre-wrap;
      }
    }
  }

  .invite-task-three-reward-rule-close-btn-part-box {
    padding: 0px 0px 10px 10px;
    box-sizing: border-box;
    position: absolute;
    top: 30px;
    right: 30px;
    .images-three-reward-rule-close {
      width: 48px;
      height: 48px;
    }
  }
}
/* 展示提示弹窗 */
.page-custom-popup-toast-part-wrap-box {
  width: 435px;
  max-height: 60vh;
  padding: 40px 20px 50px;
  background: #f7f6cd;
  border-radius: 36px;
  .custom-popup-toast-header-part-box {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    .images-toast {
      width: 56px;
      height: 56px;
    }
  }
  .custom-popup-toast-body-part-wrap-box {
    width: 100%;
    max-height: 40vh;
    overflow-y: auto;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 28px;
    color: #2f2f2f;
    text-align: left;
    line-height: 45px;
  }
  .custom-popup-toast-footer-part-wrap-box {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .toast-btn-box {
      width: 360px;
      height: 64px;
      border-radius: 40px;
      padding: 0px 48px;
      box-sizing: border-box;
      background: #4ebfd5;
      font-size: 28px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>


