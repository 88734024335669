<template>
  <div class="container" v-loading="!isRequest">
    <div class="lottery-rule-container">
      <img class="lottery-rule-pic" src="./components/assets/img17.png" alt="" />
      <div class="lottery-rule lottery-rule-margin-first">
        <div class="lottery-rule-title">
          <img class="rule-title-icon" src="./components/assets/lotteryLineImg.png" alt="" />
          <div>抽奖记录</div>
          <img class="rule-title-icon" src="./components/assets/lotteryLineImg.png" alt="" />
        </div>
        <div class="lottery-rule-content-bor">
          <div class="lottery-records">
            <div v-for="(el, index) in raffleRecordList" :key="index" class="lottery-item" :class="{ 'lottery-records-item-no-win': !el.isCashPrize,'lottery-records-item-win': el.isCashPrize,}">
              <div>{{ el.joinTime }}</div>
              <div class="bold">{{ el.isCashPrize ? "已中奖" : "未中奖" }}</div>
              <div v-if="el.isCashPrize" class="lottery-btn" @click="handleInfo(el.activityUserRaffleRecordId)">查看</div>
              <div v-else class="lottery-btn"></div>
            </div>

            <div v-if="raffleRecordList.length == 0 && isRequest" class="lottery-records-null">暂无抽奖记录~</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 中奖信息 detailInfo -->
    <mt-popup v-model="popupVisible" position="bottom" class="lottery-info-container">
      <div class="lottery-info">
        <div class="lottery-name">
          <span v-if="detailInfo.awardTypeUserWinRecordInfo && detailInfo.awardTypeUserWinRecordInfo.amount">{{ detailInfo.awardTypeUserWinRecordInfo.amount }}元</span>{{ detailInfo.awardsName }}
        </div>
        <div class="lottery-pic-box">
          <img class="lottery-pic" :src="detailInfo.awardsImgUrl" alt="" />
        </div>
        <div v-if="!(detailInfo.awardsType === 'wxRedEnvelope' &&detailInfo.cashPrizeStatus === 'received')">
          <!-- 非红包，已领取状态 -->
          <div class="plr28">
            <div class="lottery-status">
              <div class="lottery-info-item-title">兑奖状态:</div>
              <span class="lottery-status-value">{{detailInfo.cashPrizeStatus | getCashPrizeStatus}}</span>
            </div>
            <div class="lottery-term mt20">
              <div class="lottery-info-item-title">兑奖期限:</div>{{ detailInfo.cashPrizeExpireTime }}号之前
            </div>
            <div v-if="detailInfo.awardsType === 'physical'" class="redemption-type mt20">
              <div class="lottery-info-item-title">兑奖方式:</div>
              <div class="redemption-type-content">
                <label class="radio-box redemption-type-radio" v-for="(el, index) in pickUpTypeList" :key="index"><input class="input-radio" type="radio" :disabled="!isSubmit" v-model="formData.pickUpType" :value="el.value" /><span class="radio-label">{{ el.label }}</span></label>
              </div>
            </div>
            <div v-if=" detailInfo.awardsType === 'physical' && detailInfo.awardTypeUserWinRecordInfo.pickUpType === 'post' && detailInfo.awardTypeUserWinRecordInfo.expressCompany" class="lottery-status" style="margin-top: 10px">
              <div class="lottery-info-item-title">快递公司:</div>
              <span class="lottery-status-value">{{detailInfo.awardTypeUserWinRecordInfo.expressCompany}}</span>
            </div>
            <div v-if="detailInfo.awardsType === 'physical' &&detailInfo.awardTypeUserWinRecordInfo.pickUpType === 'post' &&detailInfo.awardTypeUserWinRecordInfo.expressOrderNo" class="lottery-status" style="margin-top: 10px">
              <div class="lottery-info-item-title">快递单号:</div>
              <span class="lottery-status-value">{{detailInfo.awardTypeUserWinRecordInfo.expressOrderNo}}</span>
            </div>
          </div>
          <!-- 手机号验证 -->
          <div class="mobile-verification" v-if="!(detailInfo.awardsType === 'wxRedEnvelope' && (detailInfo.cashPrizeStatus==='unclaimed'|| detailInfo.cashPrizeStatus==='wxRedEnvelopeToBeIssued'))">
            <div v-if="(detailInfo.awardsType === 'physical' &&formData.pickUpType === 'self') ||detailInfo.awardsType === 'experienceCoupon'">
              <!-- 实物兑奖类型为自提 || 体验券 -->
              <div class="redeem-store-info">
                <div class="redeem-store-info-left">兑换码:</div>
                <div class="redeem-store-info-right">{{ detailInfo.awardTypeUserWinRecordInfo.receiveCode }}</div>
              </div>
              <div class="redeem-store-info">
                <div class="redeem-store-info-left">兑奖门店:</div>
                <div class="redeem-store-info-right">{{ detailInfo.awardTypeUserWinRecordInfo.storeName }}</div>
              </div>
              <div class="redeem-store-info" v-if="detailInfo.storePhone">
                <div class="redeem-store-info-left">门店电话:</div>
                <div class="redeem-store-info-right">{{ detailInfo.storePhone }}</div>
              </div>
              <div class="redeem-store-info">
                <div class="redeem-store-info-left">门店地址:</div>
                <div class="redeem-store-info-right">{{ detailInfo.awardTypeUserWinRecordInfo.storeAddress }}</div>
              </div>
            </div>
            <div class="mobile-num mb8" v-if="detailInfo.awardsType === 'physical' &&formData.pickUpType === 'post' ">
              <div class="mobile-num-left">用户姓名:</div>
              <div class="mobile-num-right">
                <input class="mobile-input-100" :disabled="!isSubmit" v-model="formData.consigneeName" placeholder="请输入用户姓名" />
              </div>
            </div>
            <div class="mobile-num mb8" v-if="(detailInfo.awardsType === 'physical' &&formData.pickUpType === 'post') ||detailInfo.cashPrizeStatus === 'toBeVerified'">
              <div class="mobile-num-left">{{detailInfo.awardsType === "physical" &&formData.pickUpType === "post"? "用户电话": "电话号码"}}:</div>
              <div class="mobile-num-right">
                <input class="mobile-input-100" :disabled="!isSubmit" v-model="formData.phone" placeholder="请输入手机号" type="tel" />
              </div>
            </div>
            <div class="mobile-num mb8" v-if="detailInfo.cashPrizeStatus === 'toBeVerified'">
              <div class="mobile-num-left">验证码:</div>
              <div class="mobile-num-right">
                <input class="mobile-input" v-model="formData.smsVerifyCode" placeholder="请输入验证码" maxlength="6" type="tel" />
                <div class="send-verification">
                  <div class="verification-active" v-if="verificationFlag" @click="hanleSendCode">获取验证码</div>
                  <div class="verification-time" v-if="!verificationFlag">{{ timeNum }}s</div>
                </div>
              </div>
            </div>
            <div class="mobile-num mb8" v-if="detailInfo.cashPrizeStatus === 'toBePicVerified'">
              <div class="mobile-num-left">验证码:</div>
              <div class="mobile-num-right verified-pic-right-box">
                <input class="mobile-input" v-model="formData.smsVerifyCode" placeholder="请输入验证码" maxlength="6" type="tel" />
                <div class="send-verify-img-box" @click.stop="clickRefreshPicVerifyBtn">
                  <img class="images-verify" :src="imgVerifyUrl">
                </div>
              </div>
            </div>
            <div class="mobile-num mb8" style="align-items: flex-start !important;" v-if="detailInfo.awardsType === 'physical' &&formData.pickUpType === 'post'">
              <div class="mobile-num-left">用户地址:</div>
              <div class="mobile-num-right">
                <textarea class="mobile-textarea-100" :disabled="!isSubmit" v-model="formData.consigneeAddress" placeholder="请完整填写：省-市-区-街道-门牌号" />
                </div>
            </div>
          </div>
          <!-- 提交 -->
          <div class="lottery-submit">
            <div v-if="isSubmit" :class="['lottery-submit-btn',formData.pickUpType?'on':'']" @click="handleSubmit">
              <span v-if="detailInfo.awardsType === 'wxRedEnvelope' && detailInfo.cashPrizeStatus==='unclaimed'">领取</span>
              <span v-else>提交</span>
            </div>
            <div v-else class="lottery-submit-btn" @click="popupVisible = false">我知道了</div>
          </div>
        </div>
        <div v-if="detailInfo.awardsType === 'wxRedEnvelope' && detailInfo.cashPrizeStatus === 'received'" class="wx-red-envelope-collection">
          <!-- 中奖为红包，领取状态为：已领取 -->
          <div class="collection-time">{{ detailInfo.joinTime }}</div>
          <div class="collection-msg">已通过<div class="bold">【博士攻略】公众号</div> ，请注意查收！</div>
        </div>
      </div>
    </mt-popup>
    <!-- 提交确认呢 -->
    <mt-popup v-model="submitConfirmVisible" position="center" class="submit-confirm-container">
      <div class="submit-confirm-main">
        <img class="confirm-icon" src="./components/assets/img25.png" alt="" />
        <div class="confirm-msg">兑奖信息一经提交无法修改,请再次<span class="bold">确认是否填写无误!</span></div>
        <div class="confirm-footer">
          <div class="confirm-cancel" @click="submitConfirmVisible = false">取消</div>
          <div class="confirm-sure" @click="hanleConfirm">确定</div>
        </div>
      </div>
    </mt-popup>
    <!-- 微信公众号二维码 -->
    <mt-popup v-model="wxcodeVisible" position="center" class="submit-confirm-container">
      <div class="wxcode-main">
        <img class="wxcode-pic" src="./components/assets/img26.jpg" alt="" />
        <div class="confirm-msg">已通过博士攻略公众号下发红包，<div class="bold">请在24内小时领取!</div>
        </div>
        <div class="confirm-footer">
          <div class="confirm-sure" @click="wxcodeVisible = false">我知道了</div>
        </div>
      </div>
    </mt-popup>
  </div>
</template>
<script>
import {
  getUserRaffleRecordList,
  sendSmsVerifyCode,
  getUserRaffleDetail,
  editUserRaffleDetail,
  postPicVerifyInfo,
} from "@/request/api";
import { mapState } from "vuex";
let timer = null;
let timeNum = 60; // 手机验证码限制时间

let that = "";
export default {
  data () {
    return {
      wxcodeVisible: false, // 微信中奖红包后引导
      that: this,
      activityCode: this.$route.query.activityCode,
      isRequest: false,
      raffleRecordList: [],
      submitConfirmVisible: false,
      pickUpTypeList: [],
      verificationFlag: true, // 是否能发送验证码
      timeNum: timeNum,
      popupVisible: false, // 奖品详情弹窗
      formData: {
        // 需要提交的数据
        phone: "", //手机号，短信验证或者邮寄时必填
        smsVerifyCode: "", //短信验证码，短信验证时必填
        pickUpType: "", //提货方式，字典：activityPhysicalAwardsPickUpType，奖项类型为实体奖品时必填
        consigneeName: "", //收货人姓名，邮寄时必填
        consigneeAddress: "", //收货地址，邮寄时必填
      },
      detailInfo: {}, // 当前抽奖记录的详情
      activityUserRaffleRecordId: "", // 当前抽奖id
      imgVerifyUrl: '',  //图形验证url
    };
  },
  filters: {
    getLotteryType (val) {
      let gradeObj = {
        1: "现金红包",
        2: "实体奖品",
        3: "服务体验券",
      };
      return gradeObj[val];
    },
    getCashPrizeStatus (val) {
      // 奖品状态
      let obj = {
        toBeVerified: "待验证",
        toBePicVerified: '待验证',
        unclaimed: "待领取",
        toBeDelivered: "待发货",
        delivered: "已发货",
        received: "已领取",
        expire: "过期",
        wxRedEnvelopeFail: "微信红包发送失败",
        wxRedEnvelopeToBeIssued: "待发放",
        wxRedEnvelopeIssued: "已发放",
      };
      if (val === "unclaimed" && that.detailInfo.awardsType === "experienceCoupon") {
        return "待使用";
      }
      return obj[val];
    },
  },
  created () {
    that = this.that;
    this.init();
  },
  watch: {
    submitConfirmVisible () {
      // 关闭弹窗时候 重置定时器
      this.timeNum = timeNum;
      this.verificationFlag = true;
      if (timer) {
        clearInterval(timer);
      }
    },
  },
  mounted () { },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    isSubmit () {
      if (this.detailInfo.awardsType === "experienceCoupon") {
        // 体验券. 只有是待验证的时候需要点提交
        if (this.detailInfo.cashPrizeStatus === "toBeVerified" || this.detailInfo.cashPrizeStatus === 'toBePicVerified') {
          return true;
        } else {
          return false;
        }
      }
      if (this.detailInfo.awardsType === "physical") {
        if (this.detailInfo.cashPrizeStatus === "toBeVerified" || this.detailInfo.cashPrizeStatus === 'toBePicVerified') {
          // 待验证，需要领教
          return true;
        } else if (this.detailInfo.cashPrizeStatus === "unclaimed") {
          // 待领取
          if (!this.detailInfo.awardTypeUserWinRecordInfo.pickUpType) {
            // 如果没有报错领取方式可以领取
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      if (this.detailInfo.awardsType === "wxRedEnvelope") {
        // 红包. 只有是待验证的时候需要点提交
        if (this.detailInfo.cashPrizeStatus === "toBeVerified" || this.detailInfo.cashPrizeStatus === 'toBePicVerified' || this.detailInfo.cashPrizeStatus === "unclaimed") {
          return true;
        } else {
          return false;
        }
      }

      return false;
    },
  },
  methods: {
    handleInfo (id) {
      // 记录详情
      this.activityUserRaffleRecordId = id;
      let params = {
        activityCode: this.activityCode,
        wxOpenId: this.userInfo.wxOpenId,
        activityUserRaffleRecordId: id,
      };
      getUserRaffleDetail(params).then((res) => {
        console.log('记录详情', res)
        if (res.code == 200) {
          this.detailInfo = JSON.parse(JSON.stringify(res.data)); // 中奖详情
          if (res.data.awardsType === "physical") {
            // 奖品为实物,可选的兑奖反方式
            let pickUpTypeValList = res.data.awardTypeConfigInfo.pickUpTypeList;
            let pickUpTypeList = [{
              label: "门店自提",
              value: "self",
            }, {
              label: "快递邮寄",
              value: "post",
            }];
            this.pickUpTypeList = pickUpTypeList.filter((el) => pickUpTypeValList.includes(el.value));
            this.formData = {
              // 需要提交的数据
              phone: res.data.awardTypeUserWinRecordInfo.consigneePhone, //手机号，短信验证或者邮寄时必填
              smsVerifyCode: "", //短信验证码，短信验证时必填
              pickUpType: res.data.awardTypeUserWinRecordInfo.pickUpType, //提货方式，字典：activityPhysicalAwardsPickUpType，奖项类型为实体奖品时必填
              consigneeName: res.data.awardTypeUserWinRecordInfo.consigneeName, //收货人姓名，邮寄时必填
              consigneeAddress: res.data.awardTypeUserWinRecordInfo.consigneeAddress, //收货地址，邮寄时必填
            };
          } else {
            this.formData = {
              // 需要提交的数据
              phone: "", //手机号，短信验证或者邮寄时必填
              smsVerifyCode: "", //短信验证码，短信验证时必填
              pickUpType: "", //提货方式，字典：activityPhysicalAwardsPickUpType，奖项类型为实体奖品时必填
              consigneeName: "", //收货人姓名，邮寄时必填
              consigneeAddress: "", //收货地址，邮寄时必填
            };
          }
          if (res.data.cashPrizeStatus === 'toBePicVerified') this.clickRefreshPicVerifyBtn();  //刷新图形验证-图片
          this.popupVisible = true;
        } else {
          this.$toast({
            message: res.message,
            position: "middle",
            duration: 1500,
          });
        }
      });
    },
    init () {
      this.handlegetUserRaffleRecordList();
    },
    handlegetUserRaffleRecordList () {
      // 抽奖记录
      let params = {
        activityCode: this.activityCode,
        wxOpenId: this.userInfo.wxOpenId,
      };
      getUserRaffleRecordList(params).then((res) => {
        this.raffleRecordList = res.data || [];
        this.isRequest = true;
      });
    },
    hanleConfirm () {
      // 确认，领奖
      let params = JSON.parse(JSON.stringify(this.formData));
      params.wxOpenId = this.userInfo.wxOpenId;
      params.activityCode = this.activityCode;
      params.activityUserRaffleRecordId = this.activityUserRaffleRecordId;
      editUserRaffleDetail(params).then((res) => {
        if (res.code == 200) {
          this.submitConfirmVisible = false;
          this.popupVisible = false;
          this.handlegetUserRaffleRecordList();

          if (this.detailInfo.awardsType === "wxRedEnvelope") {
            this.wxcodeVisible = true;
          } else {
            this.$toast({
              message: "领奖信息已提交",
              position: "middle",
              duration: 1500,
            });
          }
        } else {
          this.submitConfirmVisible = false;
          this.$toast({
            message: res.message,
            position: "middle",
            duration: 1500,
          });
        }
      }).catch(() => {
        this.submitConfirmVisible = false;
      });
    },
    checkData () {
      // 校验提交的信息
      if (this.detailInfo.awardsType === "physical") {
        if (!this.formData.pickUpType) {
          this.$toast({
            message: "请选择兑奖方式",
            position: "middle",
            duration: 1500,
          });
          return false;
        } else {
          if (this.formData.pickUpType === "post") {
            if (!this.formData.consigneeName) {
              this.$toast({
                message: "用户姓名不能为空",
                position: "middle",
                duration: 1500,
              });
              return false;
            }
            if (!this.formData.phone) {
              this.$toast({
                message: "用户电话不能为空",
                position: "middle",
                duration: 1500,
              });
              return false;
            }
            if (!this.formData.consigneeAddress) {
              this.$toast({
                message: "用户地址不能为空",
                position: "middle",
                duration: 1500,
              });
              return false;
            }
          }
        }
      }
      if (this.detailInfo.cashPrizeStatus === "toBeVerified") {
        // 是否需要手机号校验
        if (!this.formData.phone) {
          this.$toast({
            message: "电话号码不能为空",
            position: "middle",
            duration: 1500,
          });
          return false;
        }
        if (!this.formData.smsVerifyCode) {
          this.$toast({
            message: "验证码不能为空",
            position: "middle",
            duration: 1500,
          });
          return false;
        }
      }
      if (this.detailInfo.cashPrizeStatus === 'toBePicVerified') {
        if (!this.formData.smsVerifyCode) {
          this.$toast({
            message: "验证码不能为空",
            position: "middle",
            duration: 1500,
          });
          return false;
        }
      }
      return true;
    },
    handleSubmit () {
      // 提交信息
      if (this.checkData()) {
        this.submitConfirmVisible = true;
        // if (
        //   this.detailInfo.cashPrizeStatus === "toBeVerified" ||
        //   this.detailInfo.awardsType === "physical"
        // ) {
        //   this.submitConfirmVisible = true;
        // } else {
        //   // 直接抽奖
        // }
      }
    },
    hanleSendCode () {
      // 发送验证码
      if (!this.formData.phone) {
        this.$toast({
          message: "手机号不能为空",
          position: "middle",
          duration: 1500,
        });
        return;
      }
      if (this.verificationFlag) {
        let params = {
          activityCode: this.activityCode, //活动编码，必填
          wxOpenId: this.userInfo.wxOpenId, //用户微信opendId, 必填
          activityUserRaffleRecordId: this.activityUserRaffleRecordId, //抽奖记录id，必填
          phone: this.formData.phone, //手机号，必填
        };
        sendSmsVerifyCode(params).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$toast({
              message: "验证码已发送至您的手机，请注意查收",
              position: "middle",
              duration: 1500,
            });
          } else {
            this.$toast({
              message: res.message,
              position: "middle",
              duration: 1500,
            });
          }
        });
        this.verificationFlag = false;
        timer = setInterval(() => {
          this.timeNum -= 1;
          if (this.timeNum < 0) {
            this.timeNum = timeNum;
            this.verificationFlag = true;
            clearInterval(timer);
          }
        }, 1000);
      }
    },


    // 刷新图形验证
    clickRefreshPicVerifyBtn () {
      postPicVerifyInfo({
        activityCode: this.activityCode, //活动编码
        wxOpenId: this.userInfo.wxOpenId, //用户微信公众号openId
        bizId: this.activityUserRaffleRecordId, //业务id，如果业务是抽奖，则传抽奖记录id，如果是邀请奖励，则传邀请奖励记录id
        bizType: 'userRaffle', //业务类型，userRaffle:用户抽奖，inviteReward:邀请奖励
      }).then(res => {
        let blob = new Blob([res], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        this.imgVerifyUrl = imageUrl;
      })
    },

  },
};
</script>
<style scoped lang='scss'>
.container {
  height: 100%;
  overflow: auto;
  background: linear-gradient(180deg, #9FF4FF 0%, #079CB0 100%);
  /* background: linear-gradient(to bottom, #f64620, #de3321); */
  padding-bottom: 40px;
}
.lottery-rule-container {
  margin-top: 26px;
  padding: 0 26px;
  /* lottery-rule-margin {
    margin-top: -40px;
  } */
  .lottery-rule-margin-first {
    margin-top: -40px;
  }
  .lottery-rule {
    position: relative;
    padding: 20px;
    background: #F7F6CD;
    border-radius: 36px;
    .lottery-rule-item {
      padding: 0 28px;
      height: 60px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      background: #f6561a;
      color: #fff;
      .lottery-rule-share-icon {
        height: 36px;
        width: 36px;
        margin-right: 12px;
      }
    }
    .lottery-rule-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4EBFD5;
      font-weight: 700;
      font-size: 36px;
      height: 50px;
      .rule-title-icon {
        width: 31px;
        height: 26px;
        margin: 0 40px;
      }
    }
    .lottery-rule-content-bor {
      margin-top: 20px;
      max-height: 84vh;
      overflow: overlay;
      border: 10px solid #DCDA86;
      box-shadow: 0px 0px 32px 0px #3594A9 inset;
      border-radius: 36px;
      background: #4EBFD5;
      color: #fff;
      padding: 20px;
      p {
        margin-bottom: 12px;
        line-height: 36px;
        font-size: 28px;
      }
    }
  }
  .lottery-rule-pic {
    width: 100%;
  }
}
.radio-label{
  /* color: #4EBFD5; */
  /* background: #4EBFD5; */
}
.radio-box input[type="radio"]:checked+.radio-label{
  color: #4EBFD5;
}
.radio-box input[type="radio"]:focus+.radio-label:before{
  border-color: #4EBFD5;
}
.radio-box input[type="radio"]:checked+.radio-label:before{
  border-color: #4EBFD5;
}
.lottery-records-item-no-win {
  height: 88px;
  display: flex;
  align-items: center;
  background: #F7F6CD;
  border-radius: 44px;
  padding: 0 17px 0 40px;
  color: #5FA5B2;
  justify-content: space-between;
  font-size: 28px;
  margin-bottom: 32px;
  opacity: 0.6;
  .lottery-btn {
    width: 120px;
  }
}
.lottery-records-item-win {
  height: 88px;
  display: flex;
  align-items: center;
  background: #F7F6CD;
  border-radius: 44px;
  padding: 0 17px 0 40px;
  color: #4EBFD5;
  justify-content: space-between;
  font-size: 28px;
  margin-bottom: 32px;
  .lottery-btn {
    width: 120px;
    height: 60px;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: linear-gradient(to bottom, #ffde67, #ff6c53);
  }
}
.lottery-item:last-child {
  margin-bottom: 0 !important;
}
.lottery-info-container {
  border-radius: 48px 48px 0 0;
}
.lottery-info {
  /* max-height: 90vh;
  overflow: auto; */
  width: 100vw;
  padding: 54px 30px;
  box-shadow: 0px -6px 24px 0px #0000001A;
  max-height: 90vh;
  overflow-y: auto;
  .lottery-name {
    color: #4EBFD5;
    font-size: 44px;
    text-align: center;
    margin-bottom: 64px;
  }
  .lottery-pic-box {
    display: flex;
    justify-content: center;
    .lottery-pic {
      width: 280px;
      height: 280px;
    }
  }
  .lottery-status {
    margin-top: 65px;
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #2f2f2f;
    .lottery-status-value {
      font-weight: 700;
    }
  }
  .lottery-term {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #2f2f2f;
  }
  .mobile-verification {
    margin-top: 48px;
    border-radius: 36px 36px 0 0;
    padding: 40px 28px;
    /* background: linear-gradient(
      to bottom,
      rgba(255, 246, 241, 1),
      rgba(255, 246, 241, 0)
    ); */
    background: linear-gradient(180deg, #DAF9FF 0%, rgba(218, 249, 255, 0) 100%);
    .mobile-num {
      display: flex;
      align-items: center;
      .mobile-num-left {
        color: #2F2F2F;
        font-size: 28px;
        width: 130px;
        text-align: left;
        margin-right: 8px;
      }
      .mobile-num-right {
        flex: 1;
        border-bottom: 2px solid #C3E3EA;
        padding: 12px;
        display: flex;
        .mobile-input {
          width: calc(100% - 160px);
          height: 32px;
          border: 0;
          background: transparent;
          outline: none;
          font-size: 28px;
          color: #2F2F2F;
        }
        .mobile-input-100 {
          width: 100%;
          height: 32px;
          border: 0;
          background: transparent;
          outline: none;
          font-size: 28px;
          color: #2F2F2F;
        }
        .mobile-textarea-100 {
          width: 100%;
          height: 100px;
          border: 0;
          background: transparent;
          outline: none;
          font-size: 28px;
          color: #2F2F2F;
        }
        .mobile-textarea-100::placeholder {
          color: #C3E3EA;
          font-size: 28px !important;
        }
        .mobile-input::placeholder {
          color: #C3E3EA;
        }
        .mobile-input-100::placeholder {
          color: #C3E3EA;

        }
      }
      .send-verification {
        width: 160px;
        display: flex;
        justify-content: center;
        .verification-active {
          color: #3d94e5;
          font-size: 28px;
          text-decoration: underline;
        }
        .verification-time {
          color: #666;
          font-size: 28px;
        }
      }
    }
    .redeem-store-info {
      display: flex;
      margin-bottom: 8px;
      .redeem-store-info-left {
        color: #2F2F2F;
        font-size: 28px;
        width: 130px;
        text-align: left;
        margin-right: 8px;
        line-height: 40px;
      }
      .redeem-store-info-right {
        width: calc(100% - 128px);
        color: #2F2F2F;
        font-size: 28px;
        text-align: left;
        margin-right: 8px;
        line-height: 40px;
      }
    }
  }
}
.mb8 {
  margin-bottom: 8px;
}
.mt20 {
  margin-top: 20px;
}
.lottery-submit {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  .lottery-submit-btn {
    /* background: #DEE4E8; */
    background: #4EBFD5;
    width: 66vw;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
  }
  .on{
    background: #4EBFD5;
  }
}
.redemption-type {
  display: flex;
  align-items: center;
  font-size: 28px;
  .redemption-type-content {
    display: flex;
    align-items: center;
    .redemption-type-radio {
      margin-right: 12px;
    }
  }
}
.lottery-info-item-title {
  width: 130px;
  text-align: left;
  margin-right: 8px;
}
.plr28 {
  padding: 0 28px;
}
.wx-red-envelope-collection {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  line-height: 40px;
  padding-bottom: 50px;
  .collection-msg {
    display: flex;
    justify-content: center;
  }
}
.submit-confirm-container {
  border-radius: 36px;
  .wxcode-main {
    width: 75vw;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .confirm-msg {
      margin-top: 20px;
      color: #2f2f2f;
      font-size: 28px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .confirm-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .confirm-sure {
        display: flex;
        align-items: center;
        background: #4EBFD5;
        /* background: #f94920; */
        height: 64px;
        padding: 0 48px;
        border-radius: 32px;
        color: #fff;
        font-size: 28px;
      }
    }
  }
  .submit-confirm-main {
    width: 60vw;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F7F6CD;
    border-radius: 36px;
    .confirm-icon {
      height: 56px;
      width: 56px;
    }
    .confirm-msg {
      margin-top: 40px;
      color: #2f2f2f;
      font-size: 24px;
    }
    .confirm-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      .confirm-cancel {
        display: flex;
        align-items: center;
        border: 2px solid #DDDB8D;
        height: 64px;
        padding: 0 48px;
        border-radius: 32px;
        color: #B5B251;
        font-size: 28px;
      }
      .confirm-sure {
        display: flex;
        align-items: center;
        background: #4EBFD5;
        height: 64px;
        padding: 0 48px;
        border-radius: 32px;
        color: #fff;
        font-size: 28px;
      }
    }
  }
}
.lottery-records-null {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
}
.wxcode-pic {
  height: 40vw;
  width: 40vw;
}

/* 图形验证 */
.verified-pic-right-box {
  justify-content: space-between;
  align-items: center;
  .send-verify-img-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .images-verify {
      width: 115px;
      height: 50px;
    }
  }
}
</style>
