// import { loginByCode } from '@/request/api'
import {
  saveToken,
  saveLoginStatus,
  saveUserInfo,
  removeToken,
  removeUserInfo,
  removeLoginStatus,
  loadLoginStatus,
  loadToken,
  loadUserInfo
} from '@/utils/cache'

const state = {
  loginStatus: loadLoginStatus(), // 登录状态 2 
  token: loadToken(), // token
  userInfo: loadUserInfo(), // 用户登录信息
  locationInfo: { // 用户经纬度信息
    latitude: "",
    longitude: ""
  }
}

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_LOCATION_INFO: (state, locationInfo) => {
    state.locationInfo = locationInfo
  },
}

const actions = {
  // 登录相关，通过code获取token和用户信息，用户根据自己的需求对接后台
  loginWechatAuth({ commit }, data) {
    commit('SET_USERINFO', saveUserInfo(data.userInfo))
    commit('SET_TOKEN', saveToken(data.accessToken))
    // const data = {
    //   code: code
    // }
    // return new Promise((resolve) => {
    //   resolve()
    //   loginByCode(data)
    //     .then(res => {
    //       console.log(res)
    //       // 存用户信息，token
    //       commit('SET_USERINFO', saveUserInfo(res.data.userInfo))
    //       commit('SET_TOKEN', saveToken(res.data.accessToken))
    //       resolve(res)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       // reject(error)
    //     })
    // })
  },
  // 设置状态
  setLoginStatus({ commit }, query) {
    if (query === 0 || query === 1) {
      // 上线打开注释，本地调试注释掉，保持信息最新
      removeToken()
      removeUserInfo()
    }
    // 设置不同的登录状态
    commit('SET_LOGIN_STATUS', saveLoginStatus(query))
  },
  // 登出
  fedLogOut() {
    // 删除token，用户信息，登陆状态
    removeToken()
    removeUserInfo()
    removeLoginStatus()
  },
  // 用户地理位置
  setLocationInfo({ commit }, locationInfo) {
    sessionStorage.setItem('LOCATION_INFO', JSON.stringify(locationInfo))
    commit('SET_LOCATION_INFO', locationInfo)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
