<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  data() {
    return {
      activeIndex: "",
      menuOptions: [
        {
          label: "首页",
          value: "Home",
        },
        {
          label: "联系我们",
          value: "Contact",
        },
      ],
    };
  },
  components: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.activeIndex = this.$route.name;
    },
    handleSelect(key) {
      this.$router.push({
        name: key,
      });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.navbar_menu {
  padding: 0 80px 0 20px;
  letter-spacing: 0.3px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
.navbar_menu .navbar_menu-left {
  display: flex;
  align-items: center;
}
.navbar_menu .navbar_menu-left .bsgl-logo {
  height: 40px;
}
.navbar_menu .navbar_menu-left .bsgl-name {
  color: rgba(82, 196, 250, 1);
  font-size: 28px;
  font-family: SourceHanSansSC;
  font-weight: 700;
  letter-spacing: 2px;
}
.navbar_menu .el-menu-item {
  line-height: 30px !important;
  height: 30px !important;
}
.footer {
  /* width: 100%;
  position: fixed; */
  bottom: 0;
  left: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
a:visited {
  text-decoration: none;
}

.footer-item a {
  color: #666;
  font-size: 12px;
}
</style>
